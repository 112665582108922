import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Grid from '@material-ui/core/Grid'

import cbs from '../../Assets/cbs.png'
import fox from '../../Assets/fox.png'
import nbc from '../../Assets/nbc.png'
import ncn from '../../Assets/ncn.png'
import ppg from '../../Assets/ppg.png'
import usa from '../../Assets/usa.png'

const useStyles = makeStyles((theme) => ({
  img: {
    width: theme.spacing(15)
  }
}))

const SectionMedia = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid
      container
      spacing={2}
      style={isSmall ? null : { padding: theme.spacing(4) }}
    >
      <Grid item xs={6} sm={4} md={2} container justify='center'>
        <img src={cbs} className={classes.img} alt='cbs_news_logo'></img>
      </Grid>
      <Grid item xs={6} sm={4} md={2} container justify='center'>
        <img src={fox} className={classes.img} alt='fox_news_logo'></img>
      </Grid>
      <Grid item xs={6} sm={4} md={2} container justify='center'>
        <img src={nbc} className={classes.img} alt='nbc_news_logo'></img>
      </Grid>
      <Grid item xs={6} sm={4} md={2} container justify='center'>
        <img src={ncn} className={classes.img} alt='ncn_news_logo'></img>
      </Grid>
      <Grid item xs={6} sm={4} md={2} container justify='center'>
        <img src={ppg} className={classes.img} alt='ppg_news_logo'></img>
      </Grid>
      <Grid item xs={6} sm={4} md={2} container justify='center'>
        <img src={usa} className={classes.img} alt='usa_news_logo'></img>
      </Grid>
    </Grid>
  )
}

export default SectionMedia
