import Box from '@material-ui/core/Box'

import LinearProgress from '@material-ui/core/LinearProgress'

const Progress = ({ value }) => {
  return (
    <Box width='100%' mt={3} mb={3}>
      <LinearProgress variant='determinate' value={value} />
    </Box>
  )
}

export default Progress
