import { useContext, useEffect, Suspense } from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import { ScrollToTop, PageView } from './Utilities'
import { v4 as uuidv4 } from 'uuid'
import { makeStyles } from '@material-ui/core/styles'
// import { makeStyles, useTheme } from '@material-ui/core/styles'
// import useMediaQuery from '@material-ui/core/useMediaQuery'
import TagManager from 'react-gtm-module'
import LogRocket from 'logrocket'
import './App.css'

import { ContentContext } from './Context/Content'
import { LawyersContext } from './Context/Lawyers'

import { useWindowSize } from './Utilities'

import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'

import { Footer, Header, Loading } from './Components'

import {
  AppointmentForm,
  Careers,
  Contact,
  GenericNotFound,
  GetRate,
  GetStarted,
  LawyerForm,
  Lawyers,
  Prepare,
  Privacy,
  Profile,
  Quote,
  Terms,
  ThankYou,
  Review,
  Simple
} from './Pages'

const setBrowserId = () => {
  if (!localStorage.getItem('browserId')) {
    const newBrowserId = uuidv4()
    localStorage.setItem('browserId', newBrowserId)
    LogRocket.identify(newBrowserId)
  } else {
    const browserId = localStorage.getItem('browserId')
    LogRocket.identify(browserId)
  }
}

const useStyles = makeStyles((theme) => ({
  rootBox: { backgroundColor: theme.palette.grey[200] },
  rootContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  }
}))

const App = () => {
  const classes = useStyles()
  // const theme = useTheme()
  // const matches = useMediaQuery(theme.breakpoints.up('md'))

  const { loadingContent, content } = useContext(ContentContext)
  const { loadingLawyers, filters, setFilters } = useContext(LawyersContext)

  const windowSize = useWindowSize()

  useEffect(() => {
    const location =
      document.location.protocol +
      '//' +
      document.location.hostname +
      document.location.pathname +
      document.location.search

    const tagManagerArgs = {
      gtmId: 'GTM-PZCJNS5',
      dataLayer: {
        originalLocation: location
      }
    }

    setBrowserId()
    sessionStorage.setItem('sessionId', uuidv4())
    sessionStorage.setItem('originalLocation', location)

    TagManager.initialize(tagManagerArgs)
  }, [])

  if (loadingContent || loadingLawyers) {
    return <Loading />
  }

  const createFormRoutes = (forms) => {
    const routes = Object.entries(forms).map((form) => form[1].route)
    const routeComponents = routes.map((route, i) => (
      <Route path={`/${route}/:lawyerId`} component={LawyerForm} key={i} />
    ))

    return routeComponents
  }

  return (
    <Suspense>
      <Box
        id='wtf'
        className={classes.rootBox}
        minHeight={`${windowSize && windowSize.height}px`}
      >
        <Router>
          <ScrollToTop />
          <PageView />
          <Header filters={filters} setFilters={setFilters} />
          <Container
            maxWidth='lg'
            disableGutters
            className={classes.rootContainer}
            style={{
              minHeight: `calc(${windowSize && windowSize.height - 288}px)`
            }}
          >
            <Switch>
              <Route
                path='/appointmentform/:lawyerId'
                component={AppointmentForm}
              />
              <Route path='/careers' component={Careers} />
              <Route path='/contact' component={Contact} />
              <Route path='/genericnotfound' component={GenericNotFound} />
              <Route path='/getrate/:lawyerId' component={GetRate} />
              <Route path='/getstarted/:step' component={GetStarted} />
              <Route exact path='/' component={Lawyers} />
              <Route path='/lawyers' component={Lawyers} />
              <Route path='/prepare' component={Prepare} />
              <Route path='/privacy' component={Privacy} />
              <Route path='/profile/:lawyerId' component={Profile} />
              <Route path='/terms' component={Terms} />
              <Route path='/thankyou' component={ThankYou} />
              <Route path='/review/:lawyerId' component={Review} />
              <Route path='/simple' component={Simple} />
              <Route path='/quote/:quoteId' component={Quote} />
              <Route path='/reviewquote/:quoteId' component={Quote} />
              {createFormRoutes(content.forms).map((e) => e)}
            </Switch>
          </Container>
          <Footer />
        </Router>
      </Box>
    </Suspense>
  )
}

export default App
