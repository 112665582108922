import { useContext, useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import DeviceDetector from 'device-detector-js'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { ContentContext } from '../../Context/Content'
import { LawyersContext } from '../../Context/Lawyers'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import { Card, Loading, ReviewSites } from '../../Components'

import Header from './Header'
import Form from './Form'

const useStyles = makeStyles((theme) => ({
  privacyBox: {
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
    fontSize: '0.75rem'
  },
  link: {
    color: theme.palette.info.main,
    textDecoration: 'underline'
  },
  card: {
    maxWidth: theme.breakpoints.values.sm
  }
}))

const AppointmentFormNew = () => {
  const classes = useStyles()
  let { lawyerId } = useParams()
  const { useLawyer } = useContext(LawyersContext)
  const { content } = useContext(ContentContext)
  const lawyer = useLawyer(lawyerId)
  const location = useLocation()
  const routerState = location.state
  const [loading, setLoading] = useState()
  const deviceDetector = new DeviceDetector()
  const [device] = useState(deviceDetector.parse(navigator.userAgent))
  const theme = useTheme()

  const intercept =
    (routerState &&
      routerState.hasOwnProperty('intercept') &&
      routerState.intercept) ||
    false

  useEffect(() => {
    if (intercept === 'fail') {
      localStorage.setItem('int', 'fail')
      localStorage.setItem('intRef', routerState.interceptFormId)
    }

    if (intercept === 'pass') {
      localStorage.setItem('int', 'pass')
      localStorage.setItem('intRef', routerState.interceptFormId)
    }
  }, [intercept, routerState])

  if (!lawyer || loading) {
    return <Loading />
  }

  let smsHref
  let number = '9175960470'

  if (device.os.name === 'iOS' || device.os.name === 'Mac') {
    smsHref = `sms:${number}&body=I would like a free 30 minute appointment with ${lawyer.fullName} on...`
  } else if (device.os.name === 'Android') {
    smsHref = `sms:${number}?body=I would like a free 30 minute appointment with $${lawyer.fullName} on...`
  } else {
    smsHref = `sms:${number}`
  }

  return (
    <Grid
      container
      direction='column'
      alignContent='center'
      spacing={2}
      style={{
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
      }}
    >
      <Grid item className={classes.card}>
        <Header lawyer={lawyer} intercept={intercept} content={content} />
      </Grid>
      <Grid item className={classes.card}>
        <Form
          lawyer={lawyer}
          routerState={routerState}
          setLoading={setLoading}
        />
      </Grid>
      <Grid item className={classes.card}>
        <Card>
          <Box className={classes.privacyBox}>
            <b>Your privacy is very important to us</b> - we will not share your
            information with anyone except this lawyer. To book anonymously, you
            can
            <a href={smsHref} className={classes.link}>
              text us
            </a>
            .
          </Box>
          <ReviewSites />
        </Card>
      </Grid>
    </Grid>
  )
}

export default AppointmentFormNew
