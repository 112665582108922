import Typography from '@material-ui/core/Typography'

import { LawyerHeaderOne } from '../../Components/LawyerHeaders'

const Header = ({ lawyer, formContent }) => {
  return (
    <>
      <LawyerHeaderOne lawyer={lawyer} />
      <Typography
        variant='body1'
        align='center'
        style={{ fontStyle: 'italic' }}
      >
        {formContent.headline}
      </Typography>
    </>
  )
}

export default Header
