import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

import Typography from '@material-ui/core/Typography'

import { Card } from '../../Components'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2)
  },
  nolawyers: {
    marginBottom: theme.spacing(2)
  }
}))

const NoLawyers = () => {
  const classes = useStyles()

  return (
    <Card>
      <Box
        height={'18rem'}
        display='flex'
        flexDirection='column'
        justifyContent='center'
        p='1.75rem'
      >
        <Typography variant='h4' align={'center'} className={classes.nolawyers}>
          No divorce lawyers meet your criteria
        </Typography>
        <Divider />
        <Typography
          variant='subtitle1'
          align={'center'}
          className={classes.textColor}
        >
          Please uncheck some filters and try again
        </Typography>
      </Box>
    </Card>
  )
}

export default NoLawyers
