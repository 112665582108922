import { useEffect, useState, useContext } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ContentContext } from '../Context/Content'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'

import { LoadingSmall } from '../Components'

import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/pro-regular-svg-icons'

// const isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)
// const isTomorrow = require('dayjs/plugin/isTomorrow')
dayjs.extend(isTomorrow)
dayjs.extend(customParseFormat)

const useStyles = makeStyles((theme) => ({
  availability3: {
    fontSize: theme.spacing(2.25)
  },
  link: {
    color: theme.palette.info.main,
    textDecoration: 'underline'
  },
  chip: {
    width: 60,
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.common.yellow,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.white,
      fontWeight: 400
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.white,
      fontWeight: 400
    }
  },
  lastChip: {
    width: 'fit-content',
    margin: theme.spacing(0.5),
    marginTop: theme.spacing(1.5)
  },
  timeGrid: {
    marginTop: theme.spacing(1),
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none'
  }
}))

const SmallCalendar = ({ lawyer }) => {
  const matches = useMediaQuery('(min-height:800px)')
  const [dates, setDates] = useState(false)
  const [times, setTimes] = useState(false)
  const [selectedTimes, setSelectedTimes] = useState(false)
  const [index, setIndex] = useState(0)
  const history = useHistory()
  const theme = useTheme()
  const classes = useStyles()
  const { content } = useContext(ContentContext)
  const campaign = content.queryParams.utm_campaign || 'none'

  useEffect(() => {
    const datesArr = []
    const numberOfDays = content.variables.maxSchedulePeriod
    let daysAdded = 0

    const xTimes = (x) => (f) => {
      if (x > 0) {
        f()
        xTimes(x - 1)(f)
      }
    }

    xTimes(numberOfDays)(async () => {
      datesArr.push(dayjs().add(daysAdded, 'day').format('MM-DD-YY'))
      ++daysAdded
    })

    const timesArr = lawyer.calendar.times

    setDates(datesArr)
    setTimes(timesArr)

    datesArr.forEach((date, i) => {
      const firstIndex = date === dayjs(timesArr[0]).format('MM-DD-YY')
      if (firstIndex) {
        setIndex(i)
      }
    })
  }, [lawyer, content.variables.maxSchedulePeriod])

  useEffect(() => {
    if (times) {
      const selectedTimes = times.filter(
        (time) => dayjs(time).format('MM-DD-YY') === dates[index]
      )
      setSelectedTimes(selectedTimes)
    }
  }, [dates, index, times])

  const getDateLabel = (date) => {
    const dateParsed = dayjs(date, 'MM-DD-YY')

    if (dateParsed.isToday()) {
      return `Today, ${dateParsed.format('MMM DD')}`
    } else if (dateParsed.isTomorrow()) {
      return `Tomorrow, ${dateParsed.format('MMM DD')}`
    } else {
      return `${dateParsed.format('ddd, MMM DD')}`
    }
  }

  const getPath = () => {
    let location
    const int = localStorage.getItem('int')
    const intRef = localStorage.getItem('intRef')

    if (int === 'fail') {
      location = {
        pathname: `/appointmentform/70396232-cfc1-4b38-86cf-fcb5a3179688`,
        state: {
          interceptFormId: intRef,
          intercept: int
        }
      }
    } else if (int === 'pass') {
      location = {
        pathname: `/appointmentform/${lawyer.id}`,
        state: { interceptFormId: intRef, intercept: int }
      }
    } else if (campaign.toLowerCase() === 'unk') {
      location = {
        pathname: `/intercept/${lawyer.id}`
      }
    } else {
      location = {
        pathname: `/appointmentform/${lawyer.id}`
      }
    }

    return location
  }

  const handleSelection = (params) => {
    let location
    const int = localStorage.getItem('int')
    const intRef = localStorage.getItem('intRef')

    if (int === 'fail') {
      location = {
        pathname: `/appointmentform/70396232-cfc1-4b38-86cf-fcb5a3179688`,
        state: {
          time: params.time,
          interceptFormId: intRef,
          intercept: int
        }
      }
    } else if (int === 'pass') {
      location = {
        pathname: `/appointmentform/${lawyer.id}`,
        state: { time: params.time, interceptFormId: intRef, intercept: int }
      }
    } else if (campaign.toLowerCase() === 'unk') {
      location = {
        pathname: `/intercept/${lawyer.id}`,
        state: { time: params.time }
      }
    } else {
      location = {
        pathname: `/appointmentform/${lawyer.id}`,
        state: { time: params.time }
      }
    }

    history.push(location)
  }

  const handlePrev = () => {
    if (index === 0) {
      return
    } else {
      setIndex((prev) => prev - 1)
    }
  }

  const handleNext = () => {
    if (index === dates.length - 1) {
      return
    } else {
      setIndex((prev) => prev + 1)
    }
  }

  if (!selectedTimes || !dates) {
    return <LoadingSmall />
  }

  return (
    <>
      {times.length === 0 ? (
        <Box width={'92%'} mt={1}>
          <Typography
            className={classes.availability3}
            variant='subtitle1'
            align='center'
            noWrap={true}
          >
            No Times Available
          </Typography>
        </Box>
      ) : (
        <Box
          width={'92%'}
          mt={1}
          display='flex'
          flexDirection='column'
          alignItems='center'
        >
          <Grid container>
            <Grid item xs={2}>
              <Box
                width='100%'
                height='100%'
                display='flex'
                justifyContent='flex-start'
                alignItems='center'
                style={{ cursor: 'pointer' }}
                onClick={handlePrev}
              >
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  size='lg'
                  color={index === 0 ? theme.palette.grey[400] : null}
                />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography
                className={classes.availability3}
                variant='subtitle1'
                align='center'
                noWrap={true}
              >
                {getDateLabel(dates[index])}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Box
                width='100%'
                height='100%'
                display='flex'
                justifyContent='flex-end'
                alignItems='center'
                style={{ cursor: 'pointer' }}
                onClick={handleNext}
              >
                <FontAwesomeIcon
                  icon={faChevronRight}
                  size='lg'
                  color={
                    index === dates.length - 1 ? theme.palette.grey[400] : null
                  }
                />
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            justify='flex-start'
            wrap={matches ? 'wrap' : 'nowrap'}
            style={
              matches
                ? { width: '272px', minHeight: theme.spacing(10) }
                : { minHeight: theme.spacing(5) }
            }
            className={classes.timeGrid}
          >
            {selectedTimes.length === 0 ? (
              <Typography
                variant='subtitle1'
                align='center'
                style={{ width: '100%', color: theme.palette.text.secondary }}
              >
                No Time Availability
              </Typography>
            ) : (
              selectedTimes.map((time) => (
                <Grid item key={time}>
                  <Chip
                    label={dayjs(time).format('hh:mm')}
                    className={classes.chip}
                    clickable
                    onClick={() =>
                      handleSelection({ time, lawyerId: lawyer.id })
                    }
                  />
                </Grid>
              ))
            )}
          </Grid>
          <Grid container justify='center'>
            <Grid
              item
              className={classes.lastChip}
              container
              alignContent='center'
            >
              <Link to={getPath()} className={classes.link}>
                View All Availability
              </Link>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default SmallCalendar
