import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import { ContentContext } from '../Context/Content'

import Box from '@material-ui/core/Box'

import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.text.white,
    height: theme.spacing(6),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    minWidth: theme.spacing(30)
  }
}))

const AppointmentButton = ({ lawyer }) => {
  const classes = useStyles()
  const { content } = useContext(ContentContext)

  const getPath = () => {
    const campaign = content.queryParams.utm_campaign || 'none'
    const int = localStorage.getItem('int')
    const intRef = localStorage.getItem('intRef')

    let pathname

    if (int === 'fail') {
      pathname = {
        pathname: `/appointmentform/70396232-cfc1-4b38-86cf-fcb5a3179688`,
        state: {
          interceptFormId: intRef,
          intercept: int
        }
      }
    } else if (int === 'pass') {
      pathname = {
        pathname: `/appointmentform/${lawyer.id}`,
        state: { interceptFormId: intRef, intercept: int }
      }
    } else if (campaign.toLowerCase() === 'unk') {
      pathname = `/intercept/${lawyer.id}`
    } else {
      pathname = `/appointmentform/${lawyer.id}`
    }

    return pathname
  }

  return (
    <Link to={getPath()}>
      <Box width='100%' display='flex' justifyContent='center'>
        <Button
          color='primary'
          variant='contained'
          disableElevation
          type='submit'
          className={classes.button}
        >
          {content && content.buttons.primary}
        </Button>
      </Box>
    </Link>
  )
}

export default AppointmentButton
