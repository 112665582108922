import { useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { LawyersContext } from '../../Context/Lawyers'
import WizardStepOne from './WizardStepOne'
import WizardStepTwo from './WizardStepTwo'
import WizardStepThree from './WizardStepThree'
import WizardStepFour from './WizardStepFour'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  header: {
    fontSize: 20,
    fontWeight: '600',
    lineHeight: 1.3
  },
  subHeader: {
    fontSize: 18,
    fontWeight: '400',
    color: '#19be8d'
  },
  question: {
    fontSize: 18,
    fontWeight: '500',
    fontStyle: 'italic'
  },
  skip: {
    color: '#00a3fa',
    textDecoration: 'underline'
  }
}))

const GetStarted = () => {
  let { step } = useParams()
  const classes = useStyles()
  const { filters, setFilters } = useContext(LawyersContext)
  const [formData, setFormData] = useState({})
  const [activeStep, setActiveStep] = useState(null)
  const matches = useMediaQuery('(max-width:320px)')

  useEffect(() => {
    setActiveStep(parseInt(step))
  }, [step])

  const handleRender = (step) => {
    switch (step) {
      case 0:
        return (
          <WizardStepOne
            classes={classes}
            formData={formData}
            setFormData={setFormData}
            filters={filters}
            setFilters={setFilters}
            setActiveStep={setActiveStep}
            matches={matches}
          />
        )

      case 1:
        return (
          <WizardStepTwo
            classes={classes}
            formData={formData}
            setFormData={setFormData}
            filters={filters}
            setFilters={setFilters}
            setActiveStep={setActiveStep}
            matches={matches}
          />
        )
      case 2:
        return (
          <WizardStepThree
            classes={classes}
            formData={formData}
            setFormData={setFormData}
            filters={filters}
            setFilters={setFilters}
            setActiveStep={setActiveStep}
            matches={matches}
          />
        )
      case 3:
        return (
          <WizardStepFour
            classes={classes}
            formData={formData}
            setFormData={setFormData}
            filters={filters}
            setFilters={setFilters}
            setActiveStep={setActiveStep}
            matches={matches}
          />
        )
      default:
        return (
          <WizardStepOne
            classes={classes}
            formData={formData}
            setFormData={setFormData}
            filters={filters}
            setFilters={setFilters}
            setActiveStep={setActiveStep}
            matches={matches}
          />
        )
    }
  }

  return <>{handleRender(activeStep)}</>
}

export default GetStarted
