import { useState, useEffect } from 'react'
import { db } from '../../firebase'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'

import Progress from './Progress'
import Question from './Question'

import { Loading } from '../../Components'

const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const Form = ({ formContent, lawyerId, routerState }) => {
  let history = useHistory()
  const [currentStep, setCurrentStep] = useState(1)
  const [additionalQuestions, setAdditionalQuestions] = useState(false)
  const [responseValue, setResponseValue] = useState(0)
  const [currentQuestion, setCurrentQuestion] = useState(false)
  const [addtionalCurrentStep, setAddtionalCurrentStep] = useState(false)
  const [progress, setProgress] = useState(null)
  const [questionValue, setQuestionValue] = useState(null)
  const [docRef, setDocRef] = useState(null)

  useEffect(() => {
    const submissionId = uuidv4()
    const createTimestamp = dayjs.utc().format()
    const originalLocation = sessionStorage.getItem('originalLocation')
    const browserId = localStorage.getItem('browserId')
    const sessionId = sessionStorage.getItem('sessionId')
    const initDoc = {
      id: submissionId,
      createTimestamp,
      sessionId,
      browserId,
      originalLocation
    }
    const sourceRef = db.collection('forms.responses')

    sourceRef.add(initDoc).then((docRef) => setDocRef(docRef.id))

    const additionalQuestions = formContent.additionalQuestions
    const questionValue = 100 / formContent.numTotalQuestions

    setAdditionalQuestions(additionalQuestions)
    setQuestionValue(questionValue)
  }, [formContent])

  useEffect(() => {
    if (currentStep) {
      const currentQuestion = formContent.qualificationQuestions.filter(
        (question) => currentStep === question.step
      )
      setProgress(currentQuestion[0].step * questionValue)
      setCurrentQuestion(...currentQuestion)
    } else {
      if (responseValue >= formContent.passValue) {
        setProgress(100)
        const responsesRef = db.collection('forms.responses').doc(docRef)
        responsesRef.update({ passed: true })
        setTimeout(() => {
          history.push({
            pathname: `/appointmentform/${lawyerId}`,
            state: routerState
              ? {
                  interceptFormId: docRef,
                  intercept: 'pass',
                  time: routerState.time
                }
              : { interceptFormId: docRef, intercept: 'pass' }
          })
        }, 450)
      } else {
        setAddtionalCurrentStep(0)
      }
    }
  }, [
    currentStep,
    responseValue,
    formContent,
    history,
    lawyerId,
    questionValue,
    docRef,
    routerState
  ])

  useEffect(() => {
    if (addtionalCurrentStep || addtionalCurrentStep === 0) {
      const totalAddtionalQuestions = formContent.numAdditionalQuestions

      if (totalAddtionalQuestions > addtionalCurrentStep) {
        setProgress((prev) => prev + questionValue)
        setCurrentQuestion(additionalQuestions[addtionalCurrentStep])
      } else {
        const responsesRef = db.collection('forms.responses').doc(docRef)
        responsesRef.update({ passed: false })
        history.push({
          pathname: `/appointmentform/70396232-cfc1-4b38-86cf-fcb5a3179688`,
          state: { interceptFormId: docRef, intercept: 'fail' }
        })
      }
    }
  }, [
    addtionalCurrentStep,
    additionalQuestions,
    formContent,
    history,
    questionValue,
    docRef
  ])

  if (!currentQuestion) {
    return <Loading />
  }

  return (
    <>
      <Progress value={progress} />
      <Question
        questionValues={currentQuestion}
        setResponseValue={setResponseValue}
        setCurrentStep={setCurrentStep}
        addtionalCurrentStep={addtionalCurrentStep}
        setAddtionalCurrentStep={setAddtionalCurrentStep}
        docRef={docRef}
      />
    </>
  )
}

export default Form
