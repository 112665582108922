import { useState, useEffect, useCallback, createContext } from 'react'
import { db, func } from '../firebase'
import dayjs from 'dayjs'
import queryString from 'query-string'

import { filterLawyers } from '../Utilities/functions'

export const LawyersContext = createContext()

export const LawyersProvider = ({ children }) => {
  const queryParams = queryString.parse(window.location.search)
  const queryParamsCounty = queryParams.county || false
  const queryParamsLocation = queryParams.location || false

  const [activeLawyers, setActiveLawyers] = useState(false)
  const [lawyers, setLawyers] = useState(null)
  const [loadingLawyers, setLoadingLawyers] = useState(true)
  const [filters, setFilters] = useState(false)
  const [cory, setCory] = useState(null)

  const randomizeTimes = (data) => {
    const timeBlocks = [
      ...new Set(data.map((time) => dayjs(time).format('YYYY-MM-DD')))
    ]

    const response = timeBlocks.flatMap((block) => {
      const times = data.filter(
        (time) => block === dayjs(time).format('YYYY-MM-DD')
      )

      let response = []

      if (times.length > 8) {
        const numTimes = Math.floor(Math.random() * (8 - 6 + 1)) + 6
        const maxTimes = times.length
        let usedIndex = []

        let i = 0

        while (i < numTimes) {
          const indexPosition = Math.floor(Math.random() * (maxTimes - 0))

          if (!usedIndex.includes(indexPosition)) {
            usedIndex.push(indexPosition)
            i++
            const time = times[indexPosition]
            response.push(time)
          }
        }
      } else {
        response.push(...times)
      }

      return response
    })

    return response.sort()
  }

  const getGoogleReviews = async (lawyer) => {
    const googlePlaceId = lawyer.googlePlaceId

    const getGoogleReviews = func.httpsCallable('getGoogleReviews')

    const googleReviews = await getGoogleReviews({
      googlePlaceId: googlePlaceId
    })

    lawyer.googleReviews = googleReviews.data
    delete lawyer.googlePlaceId

    return lawyer
  }

  const fetchActiveLawyers = useCallback(async () => {
    const sourceRef = db
      .collection('lawyers')
      .where('isActive', '==', true)
      .where('isActiveCalendar', '==', true)
      .orderBy('listingRank')
    const lawyers = await sourceRef.get().then((snapshot) => {
      return snapshot.docs.map((doc) => {
        const data = doc.data()

        const allTimes = data.calendar.times.map((time) => dayjs(time).format())
        const allTimesFiltered = allTimes.filter((time) =>
          dayjs(time).isAfter(dayjs().add(1, 'h').format())
        )
        const randomTimes = randomizeTimes(allTimesFiltered)
        const calendar = {
          timezone: data.calendar.timezone,
          times: randomTimes
        }
        const sortProperties = {
          listingRank: data.listingRank,
          yearsServed: data.yearsServed,
          rate: data.rate,
          reviewAmount: data.reviewAmount,
          firstAvailable: randomTimes[0]
        }

        data.filters.rate = data.rate

        const response = {
          id: data.id,
          userId: data.userId,
          avatar: data.avatar,
          fullName: data.fullName,
          firstName: data.nickName
            ? data.nickName
            : data.fullName.split(' ').slice(0, -1).join(' '),
          filters: data.filters,
          getRate: data.card.getRate,
          rateUrl: data.card.getRateUrl,
          profile: data.profile,
          card: data.card,
          sortProperties,
          calendar,
          quickFacts: data.quickFacts,
          compatibility: data.compatibility,
          percentFamilyLaw: data.percentFamilyLaw,
          percentGreatConversation: data.percentGreatConversation,
          reviewAmount: data.reviewAmount,
          reviewValue: data.reviewValue,
          yearsServed: dayjs().diff(dayjs(data.yearAdmitted), 'year'),
          lawSchool: data.lawSchool,
          attorneyId: data.attorneyId,
          location: data.location,
          countiesServed: data.countiesServed,
          firmName: data.firmName,
          deliveryManagement: data.deliveryManagement,
          rate: data.rate,
          shortBio: data.shortBio,
          glPhone: data.glPhone,
          googlePlaceId: data.googlePlaceId
        }
        return response
      })
    })

    //Sets initially lawyers for page to load prior to fetching reviews
    setActiveLawyers(lawyers)

    const lawyersFinal = await Promise.all(
      lawyers.map((lawyer) => getGoogleReviews(lawyer))
    )

    setActiveLawyers(lawyersFinal)
  }, [])

  useEffect(() => {
    fetchActiveLawyers()
  }, [fetchActiveLawyers])

  const getInitialFilters = async (county, location) => {
    if (county) {
      setFilters({
        counties: [county],
        genders: [],
        ratings: [],
        years: [],
        rate: 2000,
        sort: 'listingRank'
      })
    } else if (location) {
      const docRef = db.collection('googleGeoCodes').doc(location)

      const locationCounty = await docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            return doc.data().name
          } else {
            return 'Philadelphia'
          }
        })
        .catch((error) => {
          console.error('Error getting document:', error)
        })

      setFilters({
        counties: [locationCounty],
        genders: [],
        ratings: [],
        years: [],
        rate: 2000,
        sort: 'listingRank'
      })
    } else {
      setFilters({
        counties: [],
        genders: [],
        ratings: [],
        years: [],
        rate: 2000,
        sort: 'listingRank'
      })
    }
  }

  useEffect(() => {
    getInitialFilters(queryParamsCounty, queryParamsLocation)
    return () => {
      getInitialFilters(queryParamsCounty, queryParamsLocation)
    }
  }, [queryParamsCounty, queryParamsLocation])

  useEffect(() => {
    const runLawyerFilter = async (obj) => {
      const data = await filterLawyers(obj)
      setLawyers(data)
      setLoadingLawyers(false)
    }

    filters &&
      activeLawyers &&
      runLawyerFilter({ lawyers: activeLawyers, filters })
  }, [activeLawyers, filters])

  const getCory = async () => {
    const docId = 'roX9nwXMCOdMOpbbPVaK'
    const coryRef = db.collection('lawyers').doc(docId)

    const cory = await coryRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data()
        }
      })
      .catch((error) => {
        console.error('Error getting document:', error)
      })
    return setCory({
      ...cory,
      firstName: cory.nickName
        ? cory.nickName
        : cory.fullName.split(' ').slice(0, -1).join(' ')
    })
  }

  useEffect(() => {
    getCory()
  }, [])

  const useLawyer = (lawyerId) => {
    if (lawyerId === '70396232-cfc1-4b38-86cf-fcb5a3179688') {
      return cory
    } else {
      const lawyer = activeLawyers.filter((lawyer) => lawyer.id === lawyerId)[0]
      return lawyer
    }
  }

  return (
    <LawyersContext.Provider
      value={{
        lawyers,
        setLawyers,
        loadingLawyers,
        filters,
        setFilters,
        useLawyer
      }}
    >
      {children}
    </LawyersContext.Provider>
  )
}
