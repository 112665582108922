import Box from '@material-ui/core/Box'

import CircularProgress from '@material-ui/core/CircularProgress'

const LoadingSmall = () => {
  return (
    <Box
      display='flex'
      justifyContent='center'
      p={2}
      height='100%'
      width='100%'
    >
      <CircularProgress />
    </Box>
  )
}

export default LoadingSmall
