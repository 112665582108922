import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useTheme } from '@material-ui/core/styles'
import { Link } from '@material-ui/core'

const Careers = () => {
  const theme = useTheme()
  console.info(theme)
  return (
    <Box p={2} pt={4} pb={4} style={{ color: theme.palette.text.primary }}>
      <Typography variant='h4' align={'center'} gutterBottom>
        Join the Greenlight Team
      </Typography>
      <Typography
        variant='subtitle1'
        align={'center'}
        style={{ marginBottom: theme.spacing(4) }}
      >
        Looking to make a great career move? Contact us at
        {` `}
        <Link href='mailto:jobs@askgreenlight.com'>jobs@askgreenlight.com</Link>
        {` `}
        to learn more about our fast growing team.
      </Typography>
      <Typography variant='h6' gutterBottom>
        Sales
      </Typography>
      <Typography variant='body2' paragraph gutterBottom>
        At Greenlight, sales reps operate autonomously and own the full funnel,
        from prospecting to onboarding for their region.
      </Typography>
      <Typography variant='h6' gutterBottom>
        Engineering
      </Typography>
      <Typography variant='body2' paragraph gutterBottom>
        We're always on the lookout for talented full stack developers with
        experience in React, NoSQL (Cloud Firestore), Javascript, Typescript and
        Python.
      </Typography>
      <Typography variant='h6' gutterBottom>
        Marketing
      </Typography>
      <Typography variant='body2' paragraph gutterBottom>
        Are you a performance marketing wiz? Expert at SEO and/or landing page
        optimization? Our marketing team is growing.
      </Typography>
      <Typography variant='h6' gutterBottom>
        Product Management
      </Typography>
      <Typography variant='body2' paragraph gutterBottom>
        Help define the roadmap for the web's easiest way to research,
        interview, and hire legal help.
      </Typography>
    </Box>
  )
}

export default Careers
