import { makeStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxShadow: '0 16px 32px 0 rgb(0 0 0 / 7%)',
    backgroundColor: theme.palette.common.white
  }
}))

const Card = ({ children }) => {
  const classes = useStyles()

  return <Paper className={classes.paper}>{children}</Paper>
}

export default Card
