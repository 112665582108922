import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    cursor: 'pointer'
  }
}))

const Header = ({ lawyer, county }) => {
  let classes = useStyles()
  let theme = useTheme()
  let history = useHistory()
  const [countyText, setCountyText] = useState(false)

  useEffect(() => {
    const res = county
      ? `Divorce Lawyer, ${county} County`
      : `Divorce Lawyer, ${lawyer.location}`
    setCountyText(res)
  }, [county, lawyer])

  const handleClick = (lawyerId) => {
    history.push({ pathname: `/profile/${lawyerId}` })
  }

  return (
    <>
      <Avatar
        alt={lawyer.fullName}
        src={lawyer.avatar}
        onClick={() => handleClick(lawyer.id)}
        className={classes.avatar}
      ></Avatar>
      <Typography
        variant='h5'
        onClick={() => handleClick(lawyer.id)}
        style={{
          fontWeight: theme.typography.fontWeightMedium,
          cursor: 'pointer'
        }}
      >
        {lawyer.fullName}
      </Typography>
      <Typography
        variant='body1'
        style={{ fontWeight: theme.typography.fontWeightRegular }}
      >
        {countyText}
      </Typography>
    </>
  )
}

export default Header
