import { useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Box from '@material-ui/core/Box'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import { AppModal } from './'

const Rate = ({ lawyer }) => {
  let theme = useTheme()
  const [open, setOpen] = useState(false)

  let rateValue = lawyer.rate <= 350 ? 2 : 3
  let rateArr = []
  for (let i = 1; i <= 4; i++) {
    rateArr.push(rateValue >= i ? true : false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const modalProps = {
    header: <Typography variant='h6'>Affordability</Typography>,
    body: (
      <>
        <Typography variant='body1' gutterBottom>
          Greenlight gathers hourly rates and minimum fees to help you quickly
          compare baseline pricing.
        </Typography>
        <Typography variant='body1' gutterBottom>
          Since every family law case is different, talking with an attorney is
          the only way to get a reliable estimate for your situation.
        </Typography>
        <Typography variant='body1'>
          Every lawyer on Greenlight offers free consultations, so you can
          easily get a custom price quote.
        </Typography>
      </>
    ),
    footer: (
      <Button
        fullWidth
        onClick={handleClose}
        style={{
          color: theme.palette.primary.main,
          fontWeight: theme.typography.fontWeightBold
        }}
      >
        ok
      </Button>
    )
  }

  return (
    <Box display='flex' alignItems='center' style={{ cursor: 'pointer' }}>
      {rateArr.map((e, i) => {
        return (
          <Typography
            variant='body1'
            key={i}
            style={{
              padding: theme.spacing(0.25),
              color: !e ? theme.palette.grey[400] : null
            }}
            onClick={handleOpen}
          >
            $
          </Typography>
        )
      })}
      <FontAwesomeIcon
        icon={faInfoCircle}
        size='sm'
        style={{
          marginLeft: theme.spacing(0.75),
          color: theme.palette.grey[600],
          paddingBottom: theme.spacing(0.2)
        }}
        onClick={handleOpen}
      />
      <AppModal open={open} handleClose={handleClose} modalProps={modalProps} />
    </Box>
  )
}

export default Rate
