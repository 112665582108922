import { useEffect, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useScrollPosition } from '../../Utilities'
import clsx from 'clsx'
import { Link } from 'react-scroll'

import { LawyersContext } from '../../Context/Lawyers'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import CircularProgress from '@material-ui/core/CircularProgress'
import Fab from '@material-ui/core/Fab'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

import Lawyer from './Lawyer'
import QuickFacts from './QuickFacts'
import Compatibility from './Compatibility'
import Interview from './Interview'
import Biography from './Biography'
import Reviews from './Reviews'

const useStyles = makeStyles((theme) => ({
  up: {
    color: theme.palette.text.white,
    height: theme.spacing(4),
    width: theme.spacing(4),
    transform: 'scaleY(1)'
  },
  down: {
    color: theme.palette.text.white,
    height: theme.spacing(4),
    width: theme.spacing(4),
    transform: 'scaleY(-1)'
  }
}))

const Profile = () => {
  const classes = useStyles()
  const [lawyer, setLawyer] = useState(null)
  const [direction, setDirection] = useState(false)
  const { lawyers } = useContext(LawyersContext)
  let { lawyerId } = useParams()
  const theme = useTheme()

  const fab = useScrollPosition(115)
  const fabTarget = !fab ? 'down' : 'up'
  const fabOffset = !fab ? -75 : -75

  useEffect(() => {
    const lawyer =
      lawyers && lawyers.filter((lawyer) => lawyer.id === lawyerId)[0]
    setLawyer(lawyer)
  }, [lawyers, lawyerId])

  if (!lawyer) {
    return (
      <Box display='flex' justifyContent='center'>
        <CircularProgress size={60} />
      </Box>
    )
  }

  const handleClick = () => {
    setDirection(!direction)
  }

  return (
    <Grid
      id='up'
      container
      justify='center'
      spacing={2}
      style={{
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
      }}
    >
      <Grid item xs={12} sm={8}>
        <Lawyer lawyer={lawyer} />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Box id={'down'} />
        <QuickFacts quickFacts={lawyer.quickFacts} lawyer={lawyer} />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Compatibility compatibility={lawyer.compatibility} lawyer={lawyer} />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Interview lawyer={lawyer} />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Biography lawyer={lawyer} />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Reviews lawyer={lawyer} />
      </Grid>
      <Box position='fixed' top={'auto'} left={'auto'} right={20} bottom={20}>
        <Link to={fabTarget} offset={fabOffset} smooth={true} duration={1000}>
          <Fab color='primary' size='medium' onClick={handleClick}>
            <KeyboardArrowUpIcon
              className={clsx(!fab && classes.down, fab && classes.up)}
            />
          </Fab>
        </Link>
      </Box>
    </Grid>
  )
}

export default Profile
