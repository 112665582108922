import { useContext, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import LogRocket from 'logrocket'
import { func } from '../../firebase'
import { makeStyles } from '@material-ui/core/styles'

import { ContentContext } from '../../Context/Content'

import Box from '@material-ui/core/Box'

import Button from '@material-ui/core/Button'
import FormHelperText from '@material-ui/core/FormHelperText'

import { Card } from '../../Components'
import Calendar from './Calendar'
import ContactForm from './ContactForm'
import Consent from './Consent'

import { setEvent } from '../../Utilities/functions'

import dayjs from 'dayjs'
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone)

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.text.white,
    height: theme.spacing(8),
    marginTop: theme.spacing(2)
  }
}))

const validationSchema = yup.object({
  datetime: yup.date().required('Please select date and time above'),
  name: yup.string('Enter your name').max(75).required('Name is required'),
  phone: yup
    .string()
    .test('len', 'Invalid phone number', (val) => {
      let regex = /^\([0-9]+\) [0-9]+-[0-9]+$/i
      return regex.test(val)
    })
    .required('Phone number is required'),
  email: yup.string().email().max(76).required('Email address is required'),
  fields: yup.array().of(
    yup.object().shape({
      value: yup
        .string()
        .oneOf(
          ['Yes', 'No', 'Not Sure'],
          'Response must be Yes, No or Not Sure'
        )
    })
  ),
  consent: yup.object().shape({
    value: yup
      .bool()
      .oneOf([true], 'Please review and accept consent')
      .required('Please review and accept consent')
  })
})

const Form = ({ lawyer, routerState, setLoading }) => {
  const classes = useStyles()
  const history = useHistory()
  const { content } = useContext(ContentContext)
  const [selectedTime, setSelectedTime] = useState()
  const [interceptFormId, setInterceptFormId] = useState()
  const { pathname } = useLocation()

  useEffect(() => {
    setEvent({ event: 'pageview', pathname })
  }, [pathname])

  useEffect(() => {
    if (routerState) {
      setSelectedTime(routerState.time)
      setInterceptFormId(routerState.interceptFormId)
    }
  }, [routerState])

  const formik = useFormik({
    initialValues: {
      datetime: routerState && 'time' in routerState ? routerState.time : '',
      name: '',
      phone: '',
      email: '',
      fields: [
        {
          id: 8948104,
          value: '',
          options: ['Yes', 'No'],
          label: 'Do you and/or your spouse own a home?'
        },
        {
          id: 8827968,
          value: '',
          options: ['Yes', 'No'],
          label: 'Do you have a child under 18 years old?'
        },
        {
          id: 9872059,
          value: '',
          options: ['Yes', 'No', 'Not Sure'],
          label:
            'Do you and/or your spouse have a retirement account (e.g., 401k, IRA)?'
        }
      ],
      consent: {
        label: `Your contact info will be used to call for your appointment and to provide associated support, including confirmation, reminder and follow-up messages. This service does not constitute an attorney-client relationship.`,
        value: ''
      }
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      window.scrollTo(0, 0)
      const createTimestamp = dayjs().format()
      const queryParams = content.queryParams
      const browserId = localStorage.getItem('browserId')
      const gclidInitial = localStorage.getItem('gclidInitial')
      let logrocketSessionURL
      LogRocket.getSessionURL((sessionURL) => {
        logrocketSessionURL = sessionURL
      })

      const formData = { ...values, createTimestamp }
      formData.lawyerDatetime = dayjs(formData.datetime)
        .tz(lawyer.calendar.timezone)
        .format()
      formData.userId = lawyer.userId
      formData.lawyerId = lawyer.id
      formData.lawyerName = lawyer.fullName
      formData.browserId = browserId
      formData.sessionId = sessionStorage.getItem('sessionId')
      formData.originalLocation = sessionStorage.getItem('originalLocation')
      formData.gclidInitial = gclidInitial
      formData.sessionUrl = logrocketSessionURL

      formData.utm_campaign = queryParams.utm_campaign
      formData.group = queryParams.group
      formData.gclid = queryParams.gclid
      formData.county = queryParams.county
      formData.location = queryParams.location
      formData.interceptFormId = interceptFormId

      LogRocket.identify(browserId, {
        name: formData.name,
        email: formData.email
      })

      const setAppointment = func.httpsCallable('setAppointment')

      const response = await setAppointment(formData)

      if (response.data.appointmentID) {
        history.push({
          pathname: `/thankyou`,
          state: {
            datetime: values.datetime,
            phone: values.phone,
            email: values.email,
            name: values.name,
            lawyer: lawyer
          }
        })
      } else if (response.data.error === 'not_available') {
        const message =
          'Apologies for the inconveniences, this time slot is no longer available. Please select a new time slot.'
        alert(message)
        return history.push({
          pathname: `/profile/${lawyer.id}`
        })
      } else {
        const message =
          'Apologies for the inconveniences, something went wrong on our end. Please try again later.'
        alert(message)
        return history.push({
          pathname: `/lawyers`
        })
      }
    }
  })

  return (
    <Card>
      <form onSubmit={formik.handleSubmit}>
        <Calendar
          lawyer={lawyer}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          formik={formik}
        />
        <ContactForm formik={formik} />
        <Consent formik={formik} />
        <Button
          color='primary'
          variant='contained'
          fullWidth
          disableElevation
          type='submit'
          className={classes.button}
        >
          {content.buttons.form}
        </Button>
        <Box pl={3.5}>
          <FormHelperText
            className={classes.padding}
            error={
              Boolean(Object.keys(formik.touched).length) &&
              Boolean(Object.keys(formik.errors).length)
            }
          >
            {Boolean(Object.keys(formik.touched).length) &&
              Boolean(Object.keys(formik.errors).length) &&
              `Please review fields above`}
          </FormHelperText>
        </Box>
      </form>
    </Card>
  )
}

export default Form
