import { db } from '../firebase'
import dayjs from 'dayjs'
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
const isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)
const isTomorrow = require('dayjs/plugin/isTomorrow')
dayjs.extend(isTomorrow)

const filterLawyers = async (data) => {
  const filters = data.filters
  const sort = filters.sort

  let genders =
    (await filters.genders[0]) === 'No Preference' ? [] : filters.genders
  let ratings = await filters.ratings
  let years = await filters.years
  const rate = await filters.rate
  let counties = await filters.counties.concat().sort()

  years = years.map((year) => year.toLowerCase())
  ratings = ratings.map((rating) => rating.toLowerCase())
  genders = genders.map((gender) => gender.toLowerCase())
  counties = counties.map((county) => county.toLowerCase())

  const lawyers = data.lawyers

  const filteredLawyers = lawyers.filter((lawyer) => {
    const lawyerGender = lawyer.filters.gender
    const lawyerRating = lawyer.filters.rating
    const lawyerYear = lawyer.filters.years
    const lawyerRate = lawyer.filters.rate
    const lawyerCounties = lawyer.filters.counties.concat().sort()

    const filterResults = []

    if (genders.length > 0) {
      filterResults.push(genders.includes(lawyerGender.toLowerCase()))
    }

    if (ratings.length > 0) {
      filterResults.push(ratings.includes(lawyerRating.toLowerCase()))
    }

    if (years.length > 0) {
      filterResults.push(years.includes(lawyerYear.toLowerCase()))
    }

    filterResults.push(lawyerRate <= rate)

    if (counties.length > 0) {
      let countiesArr = []
      for (let i = 0; i < lawyerCounties.length; i++) {
        countiesArr.push(counties.includes(lawyerCounties[i].toLowerCase()))
      }
      filterResults.push(countiesArr.includes(true))
    }

    return filterResults.length > 0 && filterResults.includes(false)
      ? false
      : true
  })

  filteredLawyers.sort((a, b) => {
    if (['yearsServed', 'reviewAmount'].includes(sort)) {
      return b.sortProperties[sort] - a.sortProperties[sort]
    } else {
      return a.sortProperties[sort] - b.sortProperties[sort]
    }
  })

  return filteredLawyers
}

const getNextAvailable = (datetime) => {
  const today = dayjs().format()
  const maxDate = dayjs(today).add(6, 'day').format()
  const isToday = dayjs(datetime).isToday()
  const isTomorrow = dayjs(datetime).isTomorrow()

  let dynamicValue = ''

  if (isToday) {
    dynamicValue = 'today'
  } else if (isTomorrow) {
    dynamicValue = 'tomorrow'
  } else if (datetime < maxDate) {
    dynamicValue = dayjs(datetime).format('ddd')
  } else {
    dynamicValue = dayjs(datetime).format('MMM DD')
  }

  return `Available ${dynamicValue} at ${dayjs(datetime).format('hh:mm a')}`
}

const setEvent = async (params) => {
  const event = params.event
  const pathname = params.pathname
  const created = dayjs().utc().format()
  const createdLocal = dayjs().format()
  const browserId = localStorage.getItem('browserId')
  const sessionId = sessionStorage.getItem('sessionId')
  const originalLocation = sessionStorage.getItem('originalLocation')

  let data
  switch (event) {
    case 'click':
      data = {
        event,
        pathname,
        id: params.id,
        originalLocation,
        browserId,
        sessionId,
        createdLocal,
        created
      }
      break
    default:
      data = {
        event,
        pathname,
        originalLocation,
        browserId,
        sessionId,
        createdLocal,
        created
      }
      break
  }

  await db.collection('events').doc().set(data)
  return
}

export { filterLawyers, getNextAvailable, setEvent }
