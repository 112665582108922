import Logo from '../Assets/logo'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import InstagramIcon from '@material-ui/icons/Instagram'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main
  },
  navItems: {
    color: 'white',
    textAlign: 'center'
  },
  disclaimer: {
    color: 'white',
    fontSize: 12
  },
  icon: {
    color: 'white'
  },
  grid: {
    '& div': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    backgroundColor: theme.palette.primary.main,
    maxWidth: theme.spacing(100)
  }
}))

const Footer = () => {
  const classes = useStyles()

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      className={classes.root}
    >
      <Grid
        container
        direction='column'
        alignItems='center'
        className={classes.grid}
      >
        <Grid item xs={12} container justify='center'>
          <Link to={`/`} className={classes.logo}>
            <Logo fill='#ffffff' style={{ width: 142.36, height: 40 }} />
          </Link>
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={3} className={classes.navItems}>
            <Link to='/terms'>Terms</Link>
          </Grid>
          <Grid item xs={3} className={classes.navItems}>
            <Link to='/privacy'>Privacy</Link>
          </Grid>
          <Grid item xs={3} className={classes.navItems}>
            <Link to='/careers'> Careers</Link>
          </Grid>
          <Grid item xs={3} className={classes.navItems}>
            <Link to='/contact'>Contact</Link>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.disclaimer}
          container
          justify='center'
        >
          © 2021 Ask Greenlight, Inc., all rights reserved.
          <span>1500 Chestnut Street #1382, Philadelphia, PA 19102</span>
        </Grid>
        <Grid item xs={6} container>
          <Grid item xs={4}>
            <FacebookIcon fontSize='large' className={classes.icon} />
          </Grid>
          <Grid item xs={4}>
            <TwitterIcon fontSize='large' className={classes.icon} />
          </Grid>
          <Grid item xs={4}>
            <InstagramIcon fontSize='large' className={classes.icon} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Footer
