import { useContext, useState, useEffect } from 'react'
import { db } from '../../firebase'
import { Link } from 'react-router-dom'
import { LawyersContext } from '../../Context/Lawyers'
import { filterLawyers } from '../../Utilities/functions'
import { useHistory } from 'react-router-dom'
import Logos from '../../Components/ReviewSites'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'
import CircularProgress from '@material-ui/core/CircularProgress'

import dayjs from 'dayjs'
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
const isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)
const isTomorrow = require('dayjs/plugin/isTomorrow')
dayjs.extend(isTomorrow)

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(18),
    height: theme.spacing(18)
  },
  name: {
    marginTop: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium
  },
  stars: {
    color: theme.palette.common.yellow
  },
  starsGray: {
    color: theme.palette.grey[300]
  },
  marginTop: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  button: {
    color: theme.palette.text.white,
    height: theme.spacing(6),
    marginTop: theme.spacing(2)
  },
  success: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: 24
  },
  bullets: {
    marginTop: theme.spacing(2)
  }
}))

const submitForm = (data) => {
  const filters = data.filters
  const filteredLawyers = data.filteredLawyers
  const createTimestamp = dayjs().utc().format()

  try {
    const wizardData = {
      name: 'Get Started',
      county: filters.counties[0],
      gender: filters.genders[0],
      sort: filters.sort,
      totalLawyers: filteredLawyers.length,
      browserId: localStorage.getItem('browserId'),
      sessionId: sessionStorage.getItem('sessionId'),
      createTimestamp
    }

    const sourceRef = db.collection('wizard.responses').doc()
    sourceRef.set(wizardData)
  } catch (err) {
    console.error(err)
  }
}

const WizardStepFour = ({ filters, matches }) => {
  const classes = useStyles()
  const history = useHistory()
  const { lawyers } = useContext(LawyersContext)
  const [filteredLawyers, setFilteredLawyers] = useState(false)
  const [formSubmit, setFormSubmit] = useState(true)
  const [loading, setLoading] = useState(true)

  setTimeout(() => {
    setLoading(false)
  }, 1250)

  useEffect(() => {
    const runLawyerFilter = async (obj) => {
      const data = await filterLawyers(obj)
      setFilteredLawyers(data)
      formSubmit &&
        submitForm({
          filters: obj.filters,
          filteredLawyers: data
        })
      setFormSubmit(false)
      return
    }
    lawyers && filters.counties.length === 0
      ? history.push({ pathname: '/getstarted/0' })
      : runLawyerFilter({ lawyers, filters })
  }, [lawyers, filters, formSubmit, history])

  if (!filteredLawyers) {
    return (
      <Container
        style={{
          padding: '1rem 1rem 2rem 1rem',
          minHeight: 'calc(90vh)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress style={{ color: '#19be8d' }} />
      </Container>
    )
  }

  const getBody = (data) => {
    const serves = `${data.counties[0]} County`

    const gender =
      data.genders[0] === 'No Preference' ? false : `${data.genders[0]}`

    let preference = ''

    switch (data.sort) {
      case 'yearsServed':
        preference = 'Highly Experienced'
        break
      case 'firstAvailable':
        preference = 'Immediate Availability'
        break
      case 'rate':
        preference = 'Lower Hourly Rate'
        break
      case 'reviewAmount':
        preference = 'Great Online Reviews'
        break
      default:
        break
    }

    const arr = gender ? [serves, gender, preference] : [serves, preference]

    return arr.map((value) => {
      return (
        <Grid item xs={12} container key={value}>
          <Grid
            item
            xs={2}
            key={value}
            container
            justify='center'
            alignItems='center'
          >
            <CheckIcon style={{ color: '#19be8d' }} />
          </Grid>
          <Grid item xs={10}>
            <Typography variant='subtitle1'>{value}</Typography>
          </Grid>
        </Grid>
      )
    })
  }

  if (loading) {
    return (
      <Container
        style={{
          padding: '1rem 1rem 2rem 1rem',
          minHeight: 'calc(90vh)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress style={{ color: '#19be8d' }} />
      </Container>
    )
  }

  return (
    <Container style={{ padding: '1rem 1rem 2rem 1rem' }}>
      <Grid container direction='column' spacing={2}>
        <Grid item xs={12} container>
          <Grid item xs={12}>
            <Typography variant='h6' className={classes.success}>
              Success!
            </Typography>
          </Grid>
          <Grid item xs={12} container style={{ padding: '8px 0' }}>
            {getBody(filters)}
          </Grid>
          <Grid
            item
            xs={12}
            container
            justify='center'
            style={{ padding: '8px 0' }}
          >
            <Link to={`/lawyers`}>
              <Button
                color='primary'
                variant='contained'
                disableElevation
                type='submit'
                className={classes.button}
                style={{ padding: '0 1.5rem', marginBottom: '0.5rem' }}
              >
                {`See All  ${filteredLawyers.length} Matching Lawyers`}
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Logos matches={matches} />
      </Grid>
    </Container>
  )
}

export default WizardStepFour
