import { Link, useHistory } from 'react-router-dom'
import Logos from '../../Components/ReviewSites'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

const WizardStepTwo = ({ classes, filters, setFilters, matches }) => {
  let history = useHistory()

  const handleClick = (event) => {
    const value = event.currentTarget.value
    const data = { ...filters, genders: [value] }
    history.push('/getStarted/2')
    setFilters(data)
  }

  const resetFilters = () => {
    const data = {
      counties: [],
      genders: [],
      ratings: [],
      years: [],
      sort: 'listingRank'
    }
    setFilters(data)
  }

  return (
    <Container style={{ padding: '1rem 1rem 2rem 1rem' }}>
      <Grid container direction='column' spacing={2}>
        <Grid item xs={10} className={classes.header}>
          Find a Great Philadelphia Divorce Lawyer
        </Grid>
        <Grid item xs={12} className={classes.subHeader}>
          Question 2 of 3
        </Grid>
        <Grid item xs={12} className={classes.question}>
          Do you have a{' '}
          <Box
            fontWeight='fontWeightBold'
            display='inline'
            style={{ textDecoration: 'underline' }}
          >
            gender
          </Box>{' '}
          preference for your divorce lawyer?
        </Grid>
        <Grid item xs={12} container spacing={2}>
          {['Male', 'Female', 'No Preference'].map((value) => {
            return (
              <Grid item xs key={value} container justify='center'>
                <Button
                  variant='contained'
                  style={{
                    backgroundColor: '#19be8d',
                    color: 'white',
                    width: '14rem'
                  }}
                  onClick={handleClick}
                  value={value}
                >
                  {value}
                </Button>
              </Grid>
            )
          })}
        </Grid>
        <Grid item xs={12} container justify='center' className={classes.skip}>
          <Link to={'/lawyers'} onClick={resetFilters}>
            Skip - See All Lawyers
          </Link>
        </Grid>
        <Logos matches={matches} />
      </Grid>
    </Container>
  )
}

export default WizardStepTwo
