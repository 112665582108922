import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'
import Collapse from '@material-ui/core/Collapse'

const DetailsStepThree = ({ step, quote }) => {
  const theme = useTheme()

  return (
    <Collapse in={step === 3 ? true : false} timeout={1000}>
      <Fade in={step === 3 ? true : false}>
        <Box>
          <Typography
            variant='h6'
            align='center'
            style={{ marginTop: theme.spacing(2) }}
          >
            Thank you!
          </Typography>
          <Typography
            variant='caption'
            align='center'
            component='div'
            style={{ marginTop: theme.spacing(2) }}
          >
            You'll be receiving an email shortly with a link to your
            personalized payment page
          </Typography>
          <Typography
            variant='body1'
            align='center'
            style={{ marginTop: theme.spacing(2) }}
          >
            {quote.email}
          </Typography>
        </Box>
      </Fade>
    </Collapse>
  )
}

export default DetailsStepThree
