import { db } from '../../firebase'
import { useTheme } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'

import Typography from '@material-ui/core/Typography'

import QuestionButton from './QuestionButton'

const Question = ({
  questionValues,
  setResponseValue,
  setCurrentStep,
  addtionalCurrentStep,
  setAddtionalCurrentStep,
  docRef
}) => {
  const theme = useTheme()

  const handleClick = (e) => {
    const selection = e.currentTarget.value
    const answer = questionValues.possibleAnswers.filter(
      (e) => e.label === selection
    )[0]
    const value = answer.value
    const label = answer.label
    const question = questionValues.question
    const responsesRef = db.collection('forms.responses').doc(docRef)
    responsesRef.update({ [question]: label })

    if (addtionalCurrentStep || addtionalCurrentStep === 0) {
      setAddtionalCurrentStep((prev) => {
        if (prev || prev === 0) {
          return prev + 1
        }
      })
    } else {
      if (questionValues.exitQuestion) {
        setResponseValue((prev) => prev + value)
        setCurrentStep(false)
      } else {
        setResponseValue((prev) => {
          const total = prev + value
          setCurrentStep(questionValues.nextStep[total])
          return total
        })
      }
    }
  }

  return (
    <Grid container direction={'column'} align='center' spacing={2}>
      <Grid item>
        <Typography
          variant='h5'
          style={{
            fontWeight: theme.typography.fontWeightMedium,
            maxWidth: theme.spacing(50)
          }}
        >
          {questionValues.question}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='caption'>{questionValues.description}</Typography>
      </Grid>
      <Grid item>
        <Grid container direction={'column'} align='center' spacing={2}>
          {questionValues.possibleAnswers.map((answer, i) => (
            <Grid item key={i}>
              <QuestionButton
                label={answer.label}
                // value={answer.value}
                // answer={answer}
                setResponseValue={setResponseValue}
                setCurrentStep={setCurrentStep}
                addtionalCurrentStep={addtionalCurrentStep}
                setAddtionalCurrentStep={setAddtionalCurrentStep}
                handleClick={handleClick}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Question
