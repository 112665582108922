import { useState } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { useFormik } from 'formik'
import * as yup from 'yup'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import dayjs from 'dayjs'
import { db } from '../../firebase'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useLocation } from 'react-router-dom'
import { Card } from '../../Components'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  button: {
    color: theme.palette.text.white,
    height: theme.spacing(6),
    marginTop: theme.spacing(2)
  },
  header: {
    marginBottom: theme.spacing(4),
    color: theme.palette.text.primary
  },
  thankyou: {
    color: theme.palette.primary.main
  }
}))

const validationSchema = yup.object({
  name: yup.string('Enter your name').max(76).required('Name is required'),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .max(76)
    .required('Email is required'),
  message: yup
    .string('Enter your message')
    .max(240)
    .required('Message is required')
})

const Contact = () => {
  const classes = useStyles()
  const [submitting, setSubmitting] = useState(false)
  const location = useLocation()
  const routerState = location.state

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: routerState ? routerState.message : ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const createTimestamp = dayjs().format()
      const formData = { ...values, createTimestamp }
      await db
        .collection('contact.form')
        .doc()
        .set(formData)
        .then(setSubmitting(1))
      setSubmitting(2)
    }
  })

  if (submitting === 1) {
    return (
      <Box p={2} pt={4} pb={4} height={'31.625rem'}>
        <Typography variant='h4' align={'center'} className={classes.header}>
          Contact Greenlight.
        </Typography>
        <Box
          height={'70%'}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <CircularProgress size={60} />
        </Box>
      </Box>
    )
  }

  if (submitting === 2) {
    return (
      <Box p={2} pt={4} pb={4} height={'31.625rem'}>
        <Typography variant='h4' align={'center'} className={classes.header}>
          Contact Greenlight.
        </Typography>
        <Box
          height={'70%'}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <Typography
            variant='h5'
            align={'center'}
            className={classes.thankyou}
          >
            Thank you.
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box p={2} mt={2} mb={8} height={'34.625rem'}>
      <Card>
        <Typography variant='h4' align={'center'} className={classes.header}>
          Contact Greenlight.
        </Typography>
        <form className={classes.root} onSubmit={formik.handleSubmit}>
          <InputLabel htmlFor='name'>Name</InputLabel>
          <TextField
            id='name'
            variant='outlined'
            fullWidth
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <InputLabel htmlFor='email'>Email</InputLabel>
          <TextField
            id='email'
            variant='outlined'
            fullWidth
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <InputLabel htmlFor='message'>Message</InputLabel>
          <TextField
            id='message'
            variant='outlined'
            fullWidth
            multiline
            rows={4}
            value={formik.values.message}
            onChange={formik.handleChange}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
          />
          <Button
            color='primary'
            variant='contained'
            fullWidth
            disableElevation
            type='submit'
            className={classes.button}
          >
            Submit
          </Button>
        </form>
      </Card>
    </Box>
  )
}

export default Contact
