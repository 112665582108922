import { makeStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'

import Typography from '@material-ui/core/Typography'
import { Card, AppointmentButton } from '../../Components'
import Header from './Header'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary
  }
}))

const Biography = ({ lawyer }) => {
  const classes = useStyles()
  const biography = lawyer.profile.bio

  return (
    <Card>
      <Header title={'Biography'} />
      {biography.map((paragraph, i) => {
        return (
          <Container key={i} className={classes.root}>
            <Typography key={i} variant='body2' paragraph gutterBottom>
              {paragraph}
            </Typography>
          </Container>
        )
      })}
      <AppointmentButton lawyer={lawyer} />
    </Card>
  )
}

export default Biography
