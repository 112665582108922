import { Link } from 'react-scroll'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Box from '@material-ui/core/Box'

import { faStar } from '@fortawesome/free-solid-svg-icons'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  star: { paddingLeft: theme.spacing(0.25), fontSize: theme.spacing(3) },
  yellow: {
    color: theme.palette.common.yellow,
    fontSize: theme.spacing(3)
  },
  grey: {
    color: theme.palette.grey[300],
    fontSize: theme.spacing(3)
  },
  padding: {
    paddingLeft: theme.spacing(0.5),
    paddingTop: theme.spacing(0.45),
    fontSize: theme.spacing(1.75)
  }
}))

const Stars = ({ lawyer }) => {
  const classes = useStyles()

  if (!lawyer.googleReviews) {
    return <Typography variant='body1'>Reviews Loading...</Typography>
  }

  let starsArr = []
  for (let i = 1; i <= 5; i++) {
    starsArr.push(Math.ceil(lawyer.googleReviews.rating) >= i ? 1 : 0)
  }

  return (
    <Link to={'reviews'} offset={-100} smooth={true} duration={1000}>
      <Box display='flex' alignItems='center'>
        {starsArr.map((e, i) =>
          e === 1 ? (
            <FontAwesomeIcon
              key={i}
              icon={faStar}
              className={
                i === 0
                  ? `${classes.yellow}`
                  : `${classes.star} ${classes.yellow}`
              }
            />
          ) : (
            <FontAwesomeIcon
              key={i}
              icon={faStar}
              className={
                i === 0 ? `${classes.grey}` : `${classes.star} ${classes.grey}`
              }
            />
          )
        )}
        <Typography
          variant='caption'
          color='textSecondary'
          className={classes.padding}
        >{`(${lawyer.googleReviews.user_ratings_total})`}</Typography>
      </Box>
    </Link>
  )
}

export default Stars
