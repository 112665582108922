import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'

firebase.initializeApp({
  projectId: 'askgreenlight',
  appId: '1:535538352774:web:5ae607183f0319cbab4707',
  storageBucket: 'askgreenlight.appspot.com',
  apiKey: 'AIzaSyArWy0SdOkkeUstdeGJn0ohr2JhKKv5z7E',
  authDomain: 'askgreenlight-f7124.firebaseapp.com',
  messagingSenderId: '535538352774'
})

const db = firebase.firestore()
const func = firebase.functions()

// eslint-disable-next-line no-restricted-globals
if (window.location.hostname.includes('192')) {
  db.useEmulator(window.location.hostname, 8080)
  func.useEmulator(window.location.hostname, 5001)
}

// eslint-disable-next-line no-restricted-globals
if (window.location.hostname === 'localhost') {
  db.useEmulator('localhost', 8080)
  func.useEmulator('localhost', 5001)
}

export default firebase
export { firebase, db, func }
