import { Link, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'

import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import Bullets from './Bullets'

import { AppointmentButton } from '../../../Components'

import { setEvent } from '../../../Utilities/functions'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.info.main,
    textDecoration: 'underline'
  },
  fontSize: {
    fontSize: theme.spacing(1.75)
  }
}))

const About = ({ lawyer, content }) => {
  const classes = useStyles()
  const displayPhone = content.variables.displayPhone || false
  const phone = lawyer.glPhone || content.variables.phoneNumber
  const { pathname } = useLocation()

  const phoneFormatted =
    lawyer.glPhone.substring(0, 3) +
    '.' +
    lawyer.glPhone.substring(3, 6) +
    '.' +
    lawyer.glPhone.substring(6, 10)

  const handleClick = (e) => {
    const id = e.currentTarget.id
    setEvent({ event: 'click', id, pathname })
  }

  return (
    <Box
      width='100%'
      height={displayPhone ? 352 : 328}
      pt={2.5}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
    >
      <Bullets lawyer={lawyer} />
      <Box
        pt={2.5}
        style={{
          overflow: 'hidden'
        }}
      >
        <Typography variant='body1' align='left'>
          {`${lawyer.shortBio} `}
          <Link to={`./profile/${lawyer.id}`} className={classes.link}>
            {`See ${lawyer.firstName}'s Profile`}
          </Link>
        </Typography>
      </Box>
      <Box pt={2.5} pb={1}>
        <AppointmentButton lawyer={lawyer} />
        {displayPhone ? (
          <Typography
            variant='body1'
            component='div'
            align='center'
            id='click-to-call'
            onClick={handleClick}
          >
            {`Or Call Us `}
            <a href={`tel:${phone}`} className={classes.link}>
              {phoneFormatted}
            </a>
          </Typography>
        ) : null}
      </Box>
      <Divider />
    </Box>
  )
}

export default About
