import { useWindowSize } from '../Utilities'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'

import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  rootBox: { backgroundColor: theme.palette.grey[200] }
}))

const Loading = () => {
  const classes = useStyles()
  const windowSize = useWindowSize()

  return (
    <Box
      className={classes.rootBox}
      display='flex'
      justifyContent='center'
      pt={`${windowSize && windowSize.height / 3}px`}
      minHeight={`${windowSize && windowSize.height}px`}
    >
      <CircularProgress />
    </Box>
  )
}

export default Loading
