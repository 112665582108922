import { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { LawyersContext } from '../../Context/Lawyers'

import Box from '@material-ui/core/Box'

import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'

import { Stars } from '../'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10)
  }
}))

const One = ({ lawyer }) => {
  const classes = useStyles()
  const { filters } = useContext(LawyersContext)
  const [location, setLocation] = useState([])
  const [cory, setCory] = useState(false)

  useEffect(() => {
    setCory(lawyer.id === '70396232-cfc1-4b38-86cf-fcb5a3179688')
  }, [lawyer])

  useEffect(() => {
    const filteredCountiesServed = filters.counties.filter((county) =>
      lawyer.countiesServed.find((el) => el === county)
    )

    if (filteredCountiesServed.length > 0) {
      setLocation(filteredCountiesServed[0] + ' Co.')
    } else setLocation(lawyer.location)
  }, [lawyer, filters])

  return (
    <Box display='flex' mb={2}>
      <Box pl={1.5} pr={1.5}>
        <Avatar
          alt={lawyer.fullName}
          src={lawyer.avatar}
          className={classes.avatar}
        />
      </Box>
      <Box>
        {lawyer.fullName.length > 17 ? (
          <Typography
            variant='h6'
            className={classes.name}
            style={{ fontSize: '1.1rem' }}
          >
            {lawyer.fullName}
          </Typography>
        ) : (
          <Typography variant='h6' className={classes.name}>
            {lawyer.fullName}
          </Typography>
        )}
        <Box display='flex' flexDirection='column'>
          <Stars lawyer={lawyer} />
          <Typography variant='caption' noWrap>
            {cory ? location : 'Divorce Lawyer, ' + location}
          </Typography>
          <Typography variant='caption'>
            {`${lawyer.yearsServed}+ Years `}{' '}
            {cory ? 'Experince' : 'in Divorce Law'}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default One
