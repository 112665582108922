import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import Divider from '@material-ui/core/Divider'

import { Card, SmallCalendar } from '../../Components'
import { LawyerHeaderOne } from '../../Components/LawyerHeaders'

const Lawyer = ({ lawyer }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Card>
      {isSmall ? (
        <Box
          width='100%'
          display='flex'
          flexDirection='column'
          alignItems='center'
        >
          <LawyerHeaderOne lawyer={lawyer} />
          <Divider style={{ width: '100%' }} variant='middle' />
          <SmallCalendar lawyer={lawyer} />
        </Box>
      ) : (
        <Grid container justify='center'>
          <Grid item md={6}>
            <LawyerHeaderOne lawyer={lawyer} />
          </Grid>
          <Grid item md={1}>
            <Divider
              orientation={isSmall ? 'horizontal' : 'vertical'}
              variant={isSmall ? null : 'middle'}
            />
          </Grid>
          <Grid item md={5}>
            <Box width='100%'>
              <SmallCalendar lawyer={lawyer} />
            </Box>
          </Grid>
        </Grid>
      )}
    </Card>
  )
}

export default Lawyer
