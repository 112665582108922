import google_reviews from './google_reviews.webp'
import bbb_logo from './bbb_logo.png'
import Grid from '@material-ui/core/Grid'

const Logos = ({ matches }) => {
  return (
    <Grid
      item
      xs={12}
      container
      justify='space-around'
      style={{ marginTop: '1rem' }}
    >
      <a href='https://www.google.com/search?q=greenlight+legal+service+new+jersey&ei=sjxyYMm4OI-asQWM_IOYDw&oq=greenlight+legal+service+new+jersey&gs_lcp=Cgdnd3Mtd2l6EAM6BwgAEEcQsAM6CAgAEA0QBRAeOggIABAIEA0QHjoKCAAQCBANEAoQHjoFCAAQhgM6BAghEApQlglYpRVgsxZoAXACeAGAAYQBiAH8C5IBAzcuOJgBAKABAaoBB2d3cy13aXrIAQjAAQE&sclient=gws-wiz&ved=0ahUKEwjJ2cT18fTvAhUPTawKHQz-APMQ4dUDCA0&uact=5#lrd=0x89c257761b32e9eb:0xe137b14255482b7a,1,,,'>
        <img
          src={google_reviews}
          alt='google 5 star reviews'
          style={matches ? { height: '2.75rem' } : { height: '3rem' }}
        />
      </a>
      <a href='https://www.bbb.org/us/pa/phila/profile/sales-lead-generation/greenlight-0241-236060859/#sealclick'>
        <img
          src={bbb_logo}
          alt='bbb accreditation'
          style={matches ? { height: '2.75rem' } : { height: '3rem' }}
        />
      </a>
    </Grid>
  )
}

export default Logos
