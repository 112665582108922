import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Divider from '@material-ui/core/Divider'

import Grid from '@material-ui/core/Grid'

import { Card, SmallCalendar } from '../../Components'
import { LawyerHeaderOne } from '../../Components/LawyerHeaders'

const Lawyer = ({ lawyer }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Card>
      <Grid container justify='center'>
        <Grid item sm={12} md={6}>
          <LawyerHeaderOne lawyer={lawyer} />
        </Grid>
        <Grid item sm={12} md={1}>
          <Divider
            orientation={isSmall ? 'horizontal' : 'vertical'}
            variant={isSmall ? null : 'middle'}
          />
        </Grid>
        <Grid item sm={12} md={5}>
          <SmallCalendar lawyer={lawyer} />
        </Grid>
      </Grid>
    </Card>
  )
}

export default Lawyer
