import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import { ContentContext } from '../Context/Content'

import Box from '@material-ui/core/Box'

import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.text.white,
    height: theme.spacing(6),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    minWidth: theme.spacing(30)
  }
}))

const ListingButton = ({ cory }) => {
  const classes = useStyles()
  const { content } = useContext(ContentContext)

  return (
    <Link to={`/lawyers`}>
      <Box width='100%' display='flex' justifyContent='center'>
        <Button
          color='primary'
          variant='contained'
          disableElevation
          type='submit'
          className={classes.button}
        >
          {cory
            ? 'book a free consult today'
            : content && content.buttons.thankYouPage}
        </Button>
      </Box>
    </Link>
  )
}

export default ListingButton
