import { makeStyles } from '@material-ui/core/styles'
import { Element } from 'react-scroll'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import Typography from '@material-ui/core/Typography'

import Header from './Header'
import { Card, AppointmentButton, StarsLarge } from '../../Components'
import Review from '../../Components/Review'

const useStyles = makeStyles((theme) => ({
  intro: {
    paddingLeft: theme.spacing(1.75),
    paddingRight: theme.spacing(1.75),
    marginBottom: theme.spacing(2)
  },
  link: {
    color: theme.palette.info.main,
    textDecoration: 'underline'
  }
}))

const Reviews = ({ lawyer }) => {
  const classes = useStyles()

  // if (!lawyer.googleReviews) {
  // return (
  //   <Card>
  //     <Element name='reviews' />
  //     <Typography variant='body1'>Reviews Loading...</Typography>
  //   </Card>
  // )
  // }

  return (
    <Card>
      <Element name='reviews' />
      <Header title={'Reviews'} />
      {!lawyer.googleReviews ? (
        <Box pt={10} pb={10}>
          <Typography variant='h6' align='center'>
            Reviews Loading...
          </Typography>
        </Box>
      ) : (
        <>
          <Box
            width='100%'
            display='flex'
            justifyContent='center'
            mt={3}
            mb={3}
          >
            <StarsLarge lawyer={lawyer} />
          </Box>
          <Typography variant='body2' className={classes.intro}>
            {`Most recent reviews for ${lawyer.fullName} on Google:`}
          </Typography>
          <Box className={classes.intro}>
            <Grid container spacing={4}>
              {lawyer.googleReviews.reviews.map((review, i) => (
                <Grid key={i} item sm={12} md={4}>
                  <Review review={review} />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Typography variant='body1' align='center' className={classes.link}>
            <a href={lawyer.googleReviews.url} target='_blank' rel='noreferrer'>
              See All Reviews
            </a>
          </Typography>
        </>
      )}
      <AppointmentButton lawyer={lawyer} />
    </Card>
  )
}

export default Reviews
