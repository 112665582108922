import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'

import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'

import one from '../Assets/reviewer_1.jpeg'
import two from '../Assets/reviewer_2.jpeg'
import three from '../Assets/reviewer_3.jpeg'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3)
  },
  avatar: {
    height: theme.spacing(8),
    width: theme.spacing(8)
  },
  messageFont: {
    fontStyle: 'italic'
  },
  nameFont: {
    fontWeight: theme.typography.fontWeightSemiMedium
  }
}))

const Review = ({ review }) => {
  const classes = useStyles()
  const [shortText, setShortText] = useState(false)

  useEffect(() => {
    let text = review.text
    let maxTextLength = 200
    setShortText(
      review.text.length > maxTextLength
        ? `${text.substring(0, maxTextLength)}...`
        : text
    )
  }, [review])

  const getAvatar = (review) => {
    switch (review.id) {
      case 1:
        return <Avatar className={classes.avatar} alt={review.name} src={one} />

      case 2:
        return <Avatar className={classes.avatar} alt={review.name} src={two} />
      case 3:
        return (
          <Avatar className={classes.avatar} alt={review.name} src={three} />
        )
      default:
        return (
          <Avatar
            className={classes.avatar}
            alt={review.author_name}
            src={review.profile_photo_url}
          />
        )
    }
  }

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={12} container justify='center'>
        {getAvatar(review)}
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant='subtitle1'
          className={classes.messageFont}
          align='center'
        >{`"${shortText}"`}</Typography>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <Typography
          variant='subtitle1'
          className={classes.nameFont}
          align='center'
        >
          {review.author_name}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Review
