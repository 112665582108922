import { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Box from '@material-ui/core/Box'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { AppModal } from '../../../Components'

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import search from '../../../Assets/search.png'
import credit from '../../../Assets/credit.png'
import satisfaction from '../../../Assets/satisfaction.png'

const useStyles = makeStyles((theme) => ({
  icon: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: theme.spacing(2)
  },
  scroller: {
    overflow: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none'
  }
}))

const Guarantee = () => {
  let classes = useStyles()
  let theme = useTheme()
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const reasons = [
    {
      name: `Vetted Lawyers`,
      message: `We check that every Greenlight lawyer has an active license, liability insurance, and a clean disciplinary record.`,
      icon: <img src={search} alt='Search Icon' className={classes.icon} />
    },
    {
      name: `Flexible Payments`,
      message: `Every Greenlight lawyer can be hired via credit card. You can also apply for financing or installment payments.`,
      icon: <img src={credit} alt='Credit Icon' className={classes.icon} />
    },
    {
      name: `100% Satisfaction`,
      message: `If you’re unhappy, we will help you find a new lawyer and recover unused retainer funds. For any reason, anytime.`,
      icon: (
        <img
          src={satisfaction}
          alt='Satisfaction Icon'
          className={classes.icon}
        />
      )
    }
  ]

  const modalProps = {
    header: <Typography variant='h6'>Guarantee</Typography>,
    body: (
      <Box
        className={classes.scroller}
        style={{
          maxHeight: '20rem'
        }}
      >
        {reasons.map((reason, i) => {
          return (
            <Box
              key={i}
              pt={i === 0 ? 0 : 4}
              display='flex'
              flexDirection='column'
              alignItems='center'
            >
              {reason.icon}
              <Typography variant='h6' align='center'>
                {reason.name}
              </Typography>
              <Typography variant='subtitle1' align='center'>
                {reason.message}
              </Typography>
            </Box>
          )
        })}
      </Box>
    ),
    footer: (
      <Button
        fullWidth
        onClick={handleClose}
        style={{
          color: theme.palette.primary.main,
          fontWeight: theme.typography.fontWeightBold
        }}
      >
        ok
      </Button>
    )
  }

  return (
    <Box display='flex' alignItems='center' style={{ cursor: 'pointer' }}>
      <Typography variant='body1' align='left' onClick={handleOpen}>
        Greenlight Guarantee Lawyer
      </Typography>
      <FontAwesomeIcon
        icon={faInfoCircle}
        size='sm'
        style={{
          marginLeft: theme.spacing(0.75),
          color: theme.palette.primary.main
        }}
        onClick={handleOpen}
      />
      <AppModal open={open} handleClose={handleClose} modalProps={modalProps} />
    </Box>
  )
}

export default Guarantee
