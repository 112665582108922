import { scroller } from 'react-scroll'
import { useHistory } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

import { Stars, Rate } from '../../../Components'

import Guarantee from './Guarantee'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.info.main,
    textDecoration: 'underline'
  }
}))

const Bullets = ({ lawyer }) => {
  const classes = useStyles()

  const history = useHistory()

  const scrollTarget = (target) =>
    scroller.scrollTo(target, { smooth: true, duration: 1000, offset: -100 })

  const scrollToPage = (target) => {
    history.push(`/profile/${lawyer.id}`)
    setTimeout(() => {
      scrollTarget(target)
    }, 500)
  }

  return (
    <Grid container direction='column'>
      <Grid item>
        <Guarantee />
      </Grid>
      <Grid item>
        <Typography variant='body1' align='left'>
          {`${lawyer.yearsServed}+ Years in Divorce Law`}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item>
            <Stars lawyer={lawyer} />
          </Grid>
          <Grid item style={{ paddingLeft: '0.5rem' }}>
            <Typography
              variant='body1'
              onClick={() => scrollToPage('reviews')}
              className={classes.link}
            >
              See Reviews
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Rate lawyer={lawyer} />
      </Grid>
    </Grid>
  )
}

export default Bullets
