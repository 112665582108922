import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'

import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2)
  },
  text: {
    lineHeight: '1.75rem',
    fontWeight: '600',
    marginBottom: theme.spacing(3)
  },
  divider: {
    backgroundColor: theme.palette.primary.main
  }
}))

const SectionTitle = ({ title }) => {
  const classes = useStyles()

  return (
    <Grid container justify='center' className={classes.root}>
      <Grid item xs={12}>
        <Typography variant='h5' align={'center'} className={classes.text}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Divider variant='middle' className={classes.divider} />
      </Grid>
    </Grid>
  )
}

export default SectionTitle
