import { makeStyles, useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Fade from '@material-ui/core/Fade'
import Collapse from '@material-ui/core/Collapse'

import { Card, ReviewSites } from '../../Components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAward } from '@fortawesome/pro-light-svg-icons'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'

import DetailsStepOne from './DetailsStepOne'
import DetailsStepTwo from './DetailsStepTwo'
import DetailsStepThree from './DetailsStepThree'

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.text.white,
    height: theme.spacing(6),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    minWidth: theme.spacing(30)
  }
}))

const Details = ({ tab, quote, step, setStep, setSelectedQuote }) => {
  const classes = useStyles()
  const theme = useTheme()

  const invoiceValues = [
    {
      name: `Retainer for ${quote.lawyerName}`,
      amount: quote.retainer,
      amountSupport: `@/${quote.hourly}hr`
    },
    {
      name: 'Greenlight Membership',
      amount: tab === 'member' ? quote.membership : 'declined',
      amountSupport: tab === 'member' ? 'One-time fee' : ''
    },
    {
      name: 'Greenlight Discount',
      amount: tab === 'member' ? quote.discount : '($0)',
      amountSupport: ''
    }
  ]

  const getText = (tab) => {
    switch (tab) {
      case 'non-member':
        return (
          <>
            <span style={{ fontWeight: theme.typography.fontWeightBold }}>
              {`You could save ${quote.savings}`}
            </span>
            {` by joining Greenlight today!`}
          </>
        )
      default:
        return (
          <>
            <span style={{ fontWeight: theme.typography.fontWeightBold }}>
              {`You're saving ${quote.savings}`}
            </span>
            {` by joining Greenlight today!`}
          </>
        )
    }
  }

  const getInt = (string) => {
    return parseInt(string.replace(/[^0-9]/g, ''))
  }

  const handleClick = (e) => {
    const type = e.currentTarget.id
    let total = 0
    let membership = 0
    let retainer = 0

    switch (type) {
      case 'non-member':
        total = getInt(quote.total)
        retainer = getInt(quote.retainer)
        break
      default:
        total = getInt(quote.total)
        membership = getInt(quote.membership)
        retainer = getInt(quote.retainer)
        break
    }

    const data = {
      type,
      total,
      membership,
      retainer
    }

    setSelectedQuote(data)
    return setStep(2)
  }

  return (
    <Box
      style={{
        marginTop: theme.spacing(-1),
        marginBottom: theme.spacing(2),
        position: 'relative',
        zIndex: theme.zIndex.speedDial
      }}
    >
      <Card>
        <Box display='flex' flexDirection='column'>
          <Typography
            variant='caption'
            style={{ fontWeight: theme.typography.fontWeightSemiMedium }}
          >
            Bill To
          </Typography>
          <Typography variant='caption'>{quote.name}</Typography>
          <Typography variant='caption'>{quote.email}</Typography>
          <Grid container style={{ marginTop: theme.spacing(3) }}>
            <Grid item xs={3}>
              <Box width='100%' display='flex' justifyContent='center'>
                <FontAwesomeIcon
                  icon={tab === 'member' ? faAward : faExclamationTriangle}
                  size='3x'
                  color={
                    tab === 'member'
                      ? `${theme.palette.primary.main}`
                      : `${theme.palette.error.dark}`
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography variant='body1'>{getText(tab)}</Typography>
            </Grid>
          </Grid>
          <Divider style={{ marginTop: theme.spacing(3) }} />
          <DetailsStepOne invoiceValues={invoiceValues} tab={tab} step={step} />
          <DetailsStepTwo
            step={step}
            setStep={setStep}
            setSelectedQuote={setSelectedQuote}
          />
          <DetailsStepThree step={step} quote={quote} />
          <Divider style={{ marginTop: theme.spacing(2) }} />
          <Grid container style={{ marginTop: theme.spacing(2) }}>
            <Grid item xs={6}>
              <Typography
                variant='h6'
                style={{ fontWeight: theme.typography.fontWeightSemiMedium }}
              >
                Total
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant='h6'
                align='right'
                style={{ fontWeight: theme.typography.fontWeightSemiMedium }}
              >
                {tab === 'member' ? quote.total : quote.retainer}
              </Typography>
            </Grid>
          </Grid>
          <Typography
            variant='body2'
            color='textSecondary'
            style={{ fontStyle: 'italic' }}
          >
            {`Your retainer with ${quote.lawyerName} will be credited for ${quote.retainer}`}
          </Typography>
          <Collapse in={step === 1 ? true : false} timeout={1000}>
            <Fade in={step === 1 ? true : false}>
              <Box width='100%' display='flex' justifyContent='center'>
                <Button
                  variant='contained'
                  disableElevation
                  type='submit'
                  id={tab === 'member' ? 'member' : 'non-member'}
                  onClick={handleClick}
                  className={classes.button}
                  style={
                    tab === 'member'
                      ? {
                          marginTop: theme.spacing(2),
                          backgroundColor: theme.palette.primary.main
                        }
                      : {
                          marginTop: theme.spacing(2),
                          backgroundColor: theme.palette.text.secondary
                        }
                  }
                >
                  {tab === 'member'
                    ? 'continue as greenlight member'
                    : 'continue as non-member'}
                </Button>
              </Box>
            </Fade>
          </Collapse>
          <Box style={{ marginTop: theme.spacing(2) }}>
            <ReviewSites />
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

export default Details
