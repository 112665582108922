import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.text.white,
    height: theme.spacing(6),
    marginTop: theme.spacing(2)
  }
}))

const GetStartedButton = () => {
  const classes = useStyles()
  return (
    <Link to={`/getstarted`}>
      <Button
        color='primary'
        variant='contained'
        disableElevation
        type='submit'
        className={classes.button}
        style={{ padding: '0 1.5rem', marginBottom: '0.5rem' }}
      >
        Help Me Choose
      </Button>
    </Link>
  )
}

export default GetStartedButton
