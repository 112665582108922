import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark } from '@fortawesome/pro-light-svg-icons'
import { faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons'

import { setEvent } from '../../../Utilities/functions'

const Bookmark = () => {
  const [bookmark, setBookmark] = useState(false)
  const theme = useTheme()
  const { pathname } = useLocation()

  const handleClick = (e) => {
    const id = e.currentTarget.id
    setEvent({ event: 'click', id, pathname })
    setBookmark((prev) => !prev)
  }

  return (
    <Box position='absolute' right={15} top={-26}>
      <FontAwesomeIcon
        id={`lawyer-card-bookmark-${!bookmark}`}
        color={`${theme.palette.primary.main}`}
        icon={bookmark ? faBookmarkSolid : faBookmark}
        size='2x'
        onClick={handleClick}
      />
    </Box>
  )
}

export default Bookmark
