import { useLocation } from 'react-router-dom'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { setEvent } from '../../Utilities/functions'

const useStyles = makeStyles((theme) => ({
  font: {
    fontSize: theme.spacing(1.5)
  },
  paper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1.75)
  },
  selected: {
    boxShadow: '0 16px 32px 0 rgb(0 0 0 / 7%)',
    backgroundColor: theme.palette.common.white
  },
  notSelected: {
    boxShadow: '0 0px 0px 0 rgb(0 0 0 / 0%)',
    backgroundColor: theme.palette.grey[200]
  },
  fontSelected: {
    fontWeight: theme.typography.fontWeightSemiMedium,
    color: theme.palette.primary.main
  },
  fontSelectedError: {
    fontWeight: theme.typography.fontWeightSemiMedium,
    color: theme.palette.error.dark
  }
}))

const Quote = ({ tab, setTab, step, setStep, selectedQuote, isQuotePath }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { pathname } = useLocation()

  const handleToggle = (e) => {
    const id = e.currentTarget.id
    if (step < 3) {
      setTab(id)
      setStep(1)
    }

    if (step < 3 && isQuotePath) {
      setEvent({ event: 'click', id: `quote-nav-${id}`, pathname })
    }
  }

  return (
    <Grid
      container
      style={{
        marginTop: theme.spacing(2),
        position: 'relative',
        zIndex: theme.zIndex.mobileStepper,
        cursor: 'pointer'
      }}
    >
      <Grid item xs={6}>
        <Paper
          id='member'
          onClick={handleToggle}
          className={
            tab === 'member'
              ? `${classes.paper} ${classes.selected}`
              : `${classes.paper} ${classes.notSelected}`
          }
          style={
            step < 3 || (step > 2 && selectedQuote.type === 'member')
              ? {}
              : { display: 'none' }
          }
        >
          <Typography
            variant='body1'
            align='center'
            className={
              tab === 'member'
                ? `${classes.font} ${classes.fontSelected}`
                : `${classes.font} `
            }
          >
            Greenlight Membership
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper
          id='non-member'
          onClick={handleToggle}
          className={
            tab === 'non-member'
              ? `${classes.paper} ${classes.selected}`
              : `${classes.paper} ${classes.notSelected}`
          }
          style={
            step < 3 || (step > 2 && selectedQuote.type === 'non-member')
              ? {}
              : { display: 'none' }
          }
        >
          <Typography
            variant='body1'
            align='center'
            className={
              tab === 'non-member'
                ? `${classes.font} ${classes.fontSelectedError}`
                : `${classes.font} `
            }
          >
            Non-Member
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Quote
