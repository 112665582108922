import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Grid from '@material-ui/core/Grid'

import Typography from '@material-ui/core/Typography'

import SectionTitle from './SectionTitle'
import ScrollButton from './ScrollButton'

const useStyles = makeStyles((theme) => ({
  font: {
    fontSize: '1.3rem',
    lineHeight: '1.5',
    marginBottom: theme.spacing(2)
  }
}))

const SectionAccessible = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid
      container
      justify='center'
      style={
        isSmall
          ? null
          : {
              padding: theme.spacing(4),
              paddingLeft: theme.spacing(20),
              paddingRight: theme.spacing(20)
            }
      }
    >
      <Grid item xs={12}>
        <SectionTitle title={`We Make Lawyers Accessible`} />
      </Grid>
      <Grid item xs={11}>
        <Typography variant='body1' align='center' className={classes.font}>
          Lawyers are busy serving their clients & often don't answer the phone.
          Greenlight gets you on their calendar.
        </Typography>
      </Grid>
      <Grid item xs={12} container justify='center'>
        <ScrollButton title={'book a free consulation'} />
      </Grid>
    </Grid>
  )
}

export default SectionAccessible
