import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  viewButtonLabel: { textTransform: 'none' },
  button: {
    color: theme.palette.text.white,
    height: theme.spacing(6),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    minWidth: '20rem'
  }
}))

const QuestionButton = ({ label, handleClick }) => {
  const classes = useStyles()

  return (
    <Button
      color='primary'
      variant='contained'
      disableElevation
      fullWidth
      value={label}
      onClick={handleClick}
      className={classes.button}
      classes={{ label: classes.viewButtonLabel }}
    >
      {label}
    </Button>
  )
}

export default QuestionButton
