import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { LawyerHeaderOne } from '../../Components/LawyerHeaders'

import Box from '@material-ui/core/Box'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Typography from '@material-ui/core/Typography'

import { Card } from '../../Components'

import { faCheck } from '@fortawesome/pro-regular-svg-icons'

import Intercept from './Intercept'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10)
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold
  },
  marginTop: {
    marginTop: theme.spacing(2.5)
  },
  textWhite: {
    color: theme.palette.common.white
  },
  icon: {
    color: theme.palette.primary.main
  }
}))

const Header = ({ lawyer, intercept, content }) => {
  const classes = useStyles()
  const [arr, setArr] = useState([])
  const [cory, setCory] = useState(false)

  useEffect(() => {
    setCory(lawyer.id === '70396232-cfc1-4b38-86cf-fcb5a3179688')
  }, [lawyer])

  useEffect(() => {
    if (cory) {
      setArr([
        'Get your questions answered',
        'Cory will call you at the time you pick',
        'Info on how we resolve divorce cases'
      ])
    } else {
      setArr([
        '30 minute phone consultation',
        'Assesment of your case & pricing',
        'Answers to your questions'
      ])
    }
  }, [cory])

  return (
    <Card>
      <LawyerHeaderOne lawyer={lawyer} />
      {intercept === 'fail' ? (
        <Intercept content={content} />
      ) : (
        <>
          <Typography
            variant='body1'
            align='center'
            className={classes.marginTop}
          >
            {'Book your  '}
            <span className={classes.bold}>100% free phone</span>
            {' consult'}
          </Typography>
          <Box
            display='flex'
            alignContent='center'
            alignItems='center'
            flexDirection='column'
            className={classes.marginTop}
          >
            <Box>
              {arr.map((e, i) => {
                return (
                  <Box key={i} display='flex' alignItems='center'>
                    <Box
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      pr={1}
                    >
                      <FontAwesomeIcon
                        icon={faCheck}
                        fixedWidth
                        className={classes.icon}
                      />
                    </Box>
                    <Box>
                      {cory ? (
                        <Typography
                          variant='body1'
                          style={{ fontSize: '0.9rem' }}
                          gutterBottom
                        >
                          {e}
                        </Typography>
                      ) : (
                        <Typography variant='body1' gutterBottom>
                          {e}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                )
              })}
            </Box>
          </Box>
        </>
      )}
    </Card>
  )
}

export default Header
