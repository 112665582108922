import { useContext } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { LawyersContext } from '../../Context/Lawyers'
import { ContentContext } from '../../Context/Content'

import Grid from '@material-ui/core/Grid'

import { Card, ReviewSites } from '../../Components'

import Header from './Header'
import Form from './Form'

const LawyerForm = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  const { content } = useContext(ContentContext)
  const { useLawyer } = useContext(LawyersContext)
  const location = useLocation()
  const routerState = location.state
  const pathname = location.pathname.substring(
    1,
    location.pathname.lastIndexOf('/')
  )
  const formContent = content.forms[pathname]
  let { lawyerId } = useParams()
  let lawyer = useLawyer(lawyerId)

  return (
    <Grid
      container
      direction='column'
      alignContent='center'
      spacing={2}
      style={{
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
      }}
    >
      <Grid xs={12} item>
        <Card>
          <Grid
            container
            direction='column'
            style={
              isSmall
                ? {
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(1)
                  }
                : {
                    minWidth: theme.spacing(60),
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(1)
                  }
            }
          >
            <Grid item xs={12}>
              <Header lawyer={lawyer} formContent={formContent} />
            </Grid>
            <Grid item xs={12}>
              <Form
                formContent={formContent}
                lawyerId={lawyer.id}
                routerState={routerState}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: theme.spacing(4) }}>
              <ReviewSites />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

export default LawyerForm
