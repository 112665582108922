import Typography from '@material-ui/core/Typography'

const Intercept = ({ content }) => {
  return (
    <>
      <Typography variant='body1' gutterBottom>
        {content.headlines.intercept.one}
      </Typography>
      <Typography variant='body1'>{content.headlines.intercept.two}</Typography>
    </>
  )
}

export default Intercept
