import { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Element } from 'react-scroll'

import { ContentContext } from '../../Context/Content'

import Box from '@material-ui/core/Box'
import { Card } from '../../Components/'

import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  headline: {
    fontWeight: theme.typography.fontWeightSemiMedium,
    fontSize: theme.spacing(3.1),
    marginBottom: theme.spacing(3),
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      fontSize: theme.spacing(4),
      maxWidth: '85%'
    }
  },
  headlineHighlight: {
    color: theme.palette.primary.main
  },
  subHeadline: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.spacing(2.25),

    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      fontSize: theme.spacing(2.6),
      maxWidth: '65%'
    }
  },
  subHeadlineHighlight: {
    color: theme.palette.primary.main
  },
  link: {
    color: theme.palette.info.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}))

const Hero = () => {
  const classes = useStyles()
  const { content } = useContext(ContentContext)

  const getHeadLine = (ad) => {
    switch (ad && ad.toLowerCase()) {
      case 'permatch':
        return (
          <>
            <Typography variant={'h5'} className={classes.headline}>
              {'Find a '}
              <span
                className={classes.headlineHighlight}
              >{`divorce lawyer `}</span>
              {'you can trust, at a price you can afford.'}
            </Typography>

            <Typography variant='h6' className={classes.subHeadline}>
              {`Lawyers from a `}{' '}
              <span className={classes.headlineHighlight}>{`dozen firms`}</span>
              .{' '}
              {` All the info you need to make a great
              decision. Get started by booking a free consultation.`}
            </Typography>
          </>
        )

      default:
        return (
          <>
            <Typography variant={'h5'} className={classes.headline}>
              {'Find a '}
              <span
                className={classes.headlineHighlight}
              >{`divorce lawyer `}</span>
              {'you can trust, at a price you can afford.'}
            </Typography>

            <Typography variant='h6' className={classes.subHeadline}>
              Get started by booking a free consultation.
            </Typography>
          </>
        )
    }
  }

  return (
    <Card>
      <Box display='flex' flexDirection='column'>
        {getHeadLine(content.queryParams.ad)}

        <Element name='listing-hero'></Element>
      </Box>
    </Card>
  )
}

export default Hero
