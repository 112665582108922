import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Grid from '@material-ui/core/Grid'

import Typography from '@material-ui/core/Typography'
import MuiAccordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import SectionTitle from './SectionTitle'
import ScrollButton from './ScrollButton'

const Accordion = withStyles((theme) => ({
  root: {
    backgroundColor: 'inherit',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
}))(MuiAccordion)

const AccordionDetails = withStyles((theme) => ({
  root: {}
}))(MuiAccordionDetails)

const useStyles = makeStyles((theme) => ({
  question: {
    fontWeight: theme.typography.fontWeightSemiMedium,
    [theme.breakpoints.up('md')]: {
      fontSize: theme.spacing(2.25),
      fontWeight: theme.typography.fontWeightMedium
    }
  },
  answer: {
    [theme.breakpoints.up('md')]: {
      fontSize: theme.spacing(2.2)
    }
  }
}))

const SectionQuestions = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const questions = [
    {
      question: 'How are you different than lawyer directory websites?',
      answer:
        'We provide more in-depth information about the lawyers we list, and make it easier to engage with them. Our site includes personality assessments, insider interviews, and detailed bios, so you know what you’re really getting. Our free consultations and direct scheduling make it easy to talk with lawyers as part of your process.'
    },
    {
      question: 'How does Greenlight work?',
      answer:
        'Greenlight is simple to use. Just read the lawyer profiles, pick a few that sound like a good fit for your needs, and book a free consultation with each through our real-time scheduler. After you’ve talked with each lawyer, select the best fit and use our easy onboarding process to hire them. We generally recommend interviewing three lawyers before making a selection.'
    },
    {
      question: 'How much does it cost to use Greenlight?',
      answer:
        'Greenlight is free for you to use. We are paid by the lawyers who participate in our service. The amounts we earn come from the lawyers’ marketing budgets and do not affect the rates that you will pay as a client. Each lawyer’s hourly rate is the same, whether you book through Greenlight or directly. This means you never pay extra to use our service.'
    },
    {
      question: 'Which lawyer should I hire?',
      answer:
        'We don’t make specific recommendations, because there’s no such thing as an absolute ‘best’ lawyer. Every client and every situation is different, and what matters most is the fit between your lawyer and your needs. That’s why we provide the most in-depth information on our site -- including aggregated five-star reviews, personality assessments, and extensive Q&A with each lawyer -- so you can make an educated decision.'
    },
    {
      question: 'Why should I trust Greenlight?',
      answer:
        'Greenlight is a US-based company co-founded by a retired lawyer. We periodically review the public disciplinary records of all participating lawyers. Our website is secure and we never sell or use your information without your permission. You can read reviews from other satisfied Greenlight customers on Trustpilot and Google.'
    }
  ]

  return (
    <Grid
      container
      justify='center'
      style={
        isSmall
          ? null
          : {
              padding: theme.spacing(4)
            }
      }
    >
      <Grid item xs={12}>
        <SectionTitle title={`Top 5 Questions People Ask Us`} />
      </Grid>
      <Grid item xs={12}>
        {questions.map((question) => {
          return (
            <Accordion key={question.question} square>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='subtitle2' className={classes.question}>
                  {question.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant='body1' className={classes.answer}>
                  {question.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </Grid>
      <Grid item xs={12} container justify='center'>
        <ScrollButton title={'book a free consulation'} />
      </Grid>
    </Grid>
  )
}

export default SectionQuestions
