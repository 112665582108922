import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'

import Logo from '../../Assets/logo'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'

import CountyDialog from './CountyDialog'

const useStyles = makeStyles(() => ({
  logo: {
    flexGrow: 1
  },
  fullList: {
    width: 'auto'
  },
  list: {
    fontSize: '1.2rem'
  }
}))

const Header = ({ filters, setFilters }) => {
  const classes = useStyles()
  let location = useLocation()
  let pathname = location.pathname.substring(0, 8)
  const [state, setState] = useState(false)

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState(open)
  }

  const getPath = (text) => {
    switch (text) {
      case 'Lawyer Profiles':
        return '/'
      case 'Why Greenlight?':
        return 'https://www.askgreenlight.com/whygreenlight'
      case 'About':
        return 'https://www.askgreenlight.com/about'
      default:
        return '/'
    }
  }

  const list = (
    <div
      className={classes.fullList}
      role='presentation'
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {['Lawyer Profiles', 'Why Greenlight?', 'About'].map((text) => {
          let link
          let el
          switch (text) {
            case 'Lawyer Profiles':
              link = 'lawyers'
              el = (
                <Link key={text} to={`/${link}`}>
                  <ListItem button href={getPath(text)}>
                    <Typography variant='subtitle1' className={classes.list}>
                      {text}
                    </Typography>
                  </ListItem>
                </Link>
              )
              break
            case 'Why Greenlight?':
              el = (
                <a
                  key={text}
                  href='https://www.askgreenlight.com/whygreenlight'
                >
                  <ListItem button href={getPath(text)}>
                    <Typography variant='subtitle1' className={classes.list}>
                      {text}
                    </Typography>
                  </ListItem>
                </a>
              )
              break
            case 'About':
              el = (
                <a key={text} href='https://www.askgreenlight.com/about'>
                  <ListItem button href={getPath(text)}>
                    <Typography variant='subtitle1' className={classes.list}>
                      {text}
                    </Typography>
                  </ListItem>
                </a>
              )
              break
            default:
              break
          }
          return el
        })}
      </List>
      <Divider />
      <List>
        <ListItem>
          <Button
            component={Link}
            to={'/getStarted/0'}
            variant='contained'
            style={{ backgroundColor: '#19be8d', color: 'white' }}
          >
            Help Me Choose
          </Button>
        </ListItem>
      </List>
    </div>
  )

  return (
    <AppBar
      position='sticky'
      color='inherit'
      elevation={4}
      style={{ padding: '0.25rem 0' }}
    >
      <Toolbar>
        <Link to={`/lawyers`} className={classes.logo}>
          <Logo fill='#00bd8d' style={{ width: 120, height: 33.72 }} />
        </Link>
        {pathname === '/lawyers' || pathname === '/' ? (
          <Box
            border={1}
            borderRadius={'2%'}
            borderColor='grey.300'
            bgcolor='grey.100'
          >
            <CountyDialog filters={filters} setFilters={setFilters} />
          </Box>
        ) : (
          <>
            <IconButton
              edge='start'
              onClick={toggleDrawer(true)}
              color='inherit'
              aria-label='menu'
            >
              <MenuIcon style={{ color: '#19be8d' }} />
            </IconButton>
            <SwipeableDrawer
              anchor={'top'}
              open={state}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
            >
              {list}
            </SwipeableDrawer>
          </>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default Header
