import { useState } from 'react'

import Box from '@material-ui/core/Box'

import { Card, SmallCalendar } from '../../../Components'

import Header from './Header'
import Nav from './Nav'
import About from './About'
import Qualifications from './Qualifications'
import Bookmark from './Bookmark'

const LawyerCard = ({ lawyer, county, content }) => {
  const [page, setPage] = useState('about')

  return (
    <>
      <Card>
        <Box position='relative'>
          <Bookmark />
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Header lawyer={lawyer} county={county} />
            <Nav page={page} setPage={setPage} />
            <Box>
              {page === 'about' ? (
                <About lawyer={lawyer} content={content} />
              ) : (
                <Qualifications lawyer={lawyer} content={content} />
              )}
            </Box>
            <SmallCalendar lawyer={lawyer} />
          </Box>
        </Box>
      </Card>
    </>
  )
}

export default LawyerCard
