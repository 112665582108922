import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 109.36 23.9'
      fill={props.fill}
      {...props}
    >
      <defs>
        <style>
          {'.prefix__cls-1{fill:#00bd8d}.prefix__cls-2{isolation:isolate}'}
        </style>
      </defs>
      <g id='prefix__Layer_2' data-name='Layer 2'>
        <g id='prefix__Layer_1-2' data-name='Layer 1'>
          <path d='M12.43 6.62v10.49a9.87 9.87 0 01-.38 2.82 5.52 5.52 0 01-1.19 2.14 5.4 5.4 0 01-2 1.35 7.9 7.9 0 01-2.9.48 11.23 11.23 0 01-3-.4A6.86 6.86 0 01.22 22l1.72-2.35a7.44 7.44 0 001.8 1.22 5.11 5.11 0 002.16.43 4.65 4.65 0 001.74-.3 2.71 2.71 0 001.13-.79 3 3 0 00.6-1.21 6.39 6.39 0 00.18-1.57v-.84H9.5a3.73 3.73 0 01-1.65 1.26 5.55 5.55 0 01-2.11.42 5.81 5.81 0 01-2.36-.47 5.66 5.66 0 01-1.81-1.26 5.51 5.51 0 01-1.16-1.85A6.53 6.53 0 010 12.38 7.31 7.31 0 01.38 10a5.58 5.58 0 011.13-1.91A5.4 5.4 0 013.3 6.8a5.82 5.82 0 012.39-.47A5.49 5.49 0 018 6.82a3.75 3.75 0 011.65 1.53V6.62zM6.29 8.93a3.62 3.62 0 00-1.39.25 3.06 3.06 0 00-1.07.7 3 3 0 00-.7 1.12 3.71 3.71 0 00-.25 1.4 3.22 3.22 0 001 2.35 3.1 3.1 0 001.08.72 3.34 3.34 0 001.34.27 3.61 3.61 0 001.43-.27 3 3 0 001.08-.71 3 3 0 00.69-1.06 3.76 3.76 0 00.24-1.35 3.37 3.37 0 00-.92-2.45 3.32 3.32 0 00-2.53-.97z' />
          <g className='prefix__cls-2'>
            <path d='M14.5 6.62h2.88v1.83a3.66 3.66 0 011.37-1.56 3.76 3.76 0 012-.55 3 3 0 01.52 0l.51.12v2.83a6.36 6.36 0 00-.7-.16 4.11 4.11 0 00-.69-.06 3.18 3.18 0 00-1.62.37 2.88 2.88 0 00-.93.86 2.94 2.94 0 00-.44 1 3.62 3.62 0 00-.1.72v6.16h-2.8z' />
          </g>
          <path d='M24.5 13.39a2.92 2.92 0 001 2 3.14 3.14 0 002.09.72 3.4 3.4 0 001.81-.45 4.83 4.83 0 001.28-1.11l2.07 1.56a5.92 5.92 0 01-2.25 1.79 6.67 6.67 0 01-2.62.53 6.81 6.81 0 01-2.44-.43 6 6 0 01-2-1.22 5.91 5.91 0 01-1.33-1.91 6.08 6.08 0 01-.49-2.49 6 6 0 01.49-2.48 5.67 5.67 0 013.33-3.13 6.81 6.81 0 012.44-.43 5.6 5.6 0 012.2.42A4.68 4.68 0 0131.78 8a5.62 5.62 0 011.11 2 8.45 8.45 0 01.39 2.68v.79zm5.9-2.16a2.69 2.69 0 00-.79-2 3 3 0 00-2.13-.73 2.8 2.8 0 00-2.06.74 3.34 3.34 0 00-.92 2zM37.29 13.39a2.92 2.92 0 001 2 3.16 3.16 0 002.09.72 3.4 3.4 0 001.81-.45 4.68 4.68 0 001.28-1.11l2.07 1.56a5.84 5.84 0 01-2.26 1.77 6.65 6.65 0 01-2.61.53 6.86 6.86 0 01-2.44-.41 6 6 0 01-2-1.22 5.93 5.93 0 01-1.34-1.91 6.25 6.25 0 01-.49-2.49 6.2 6.2 0 01.5-2.48A5.93 5.93 0 0136.24 8a5.83 5.83 0 012-1.22 6.86 6.86 0 012.45-.43 5.55 5.55 0 012.19.42A4.72 4.72 0 0144.58 8a5.6 5.6 0 011.1 2 8.44 8.44 0 01.4 2.68v.79zm5.91-2.16a2.69 2.69 0 00-.8-2 3 3 0 00-2.13-.73 2.76 2.76 0 00-2.05.74 3.29 3.29 0 00-.93 2zM47.78 6.62h2.74v1.85A4.22 4.22 0 0151.81 7a3.72 3.72 0 012.28-.63 4.48 4.48 0 012 .38 3.8 3.8 0 011.31 1 3.9 3.9 0 01.72 1.44 6.77 6.77 0 01.21 1.7v7.27h-2.92v-5.85a10.53 10.53 0 000-1.07 3.61 3.61 0 00-.25-1.1 2.07 2.07 0 00-.63-.86 1.79 1.79 0 00-1.18-.35 2.75 2.75 0 00-1.21.24 2.28 2.28 0 00-.82.65 2.72 2.72 0 00-.47.93 4.44 4.44 0 00-.14 1.11v6.28h-2.93z' />
          <g className='prefix__cls-2'>
            <path d='M60 0h2.88v18.14H60z' />
          </g>
          <path d='M64.63 2.71a1.66 1.66 0 01.51-1.21 1.8 1.8 0 011.31-.5 1.89 1.89 0 011.33.5 1.59 1.59 0 01.54 1.23A1.61 1.61 0 0167.78 4a1.89 1.89 0 01-1.33.49 1.76 1.76 0 01-1.31-.52 1.65 1.65 0 01-.51-1.26zM65 6.62h2.88v11.52H65zM82.1 6.62v10.49a9.52 9.52 0 01-.39 2.82 5.13 5.13 0 01-3.21 3.49 8 8 0 01-2.91.48 11.16 11.16 0 01-3-.4 7 7 0 01-2.71-1.5l1.73-2.35a7.44 7.44 0 001.8 1.22 5 5 0 002.16.43 4.46 4.46 0 001.74-.3 2.77 2.77 0 001.13-.79A3 3 0 0079 19a6.39 6.39 0 00.18-1.57v-.84a3.82 3.82 0 01-1.66 1.26 5.52 5.52 0 01-2.11.42 5.77 5.77 0 01-2.35-.47 5.5 5.5 0 01-3-3.13 6.52 6.52 0 01-.4-2.31 7.05 7.05 0 01.39-2.36 5.42 5.42 0 011.13-1.92A5.3 5.3 0 0173 6.8a5.78 5.78 0 012.38-.46 5.42 5.42 0 012.28.48 3.65 3.65 0 011.68 1.53V6.62zM76 8.93a3.66 3.66 0 00-1.39.25 3.26 3.26 0 00-1.07.7A3.13 3.13 0 0072.8 11a3.71 3.71 0 00-.25 1.4 3.31 3.31 0 00.25 1.3 3.15 3.15 0 00.71 1.05 3.1 3.1 0 001.08.72 3.31 3.31 0 001.34.27 3.64 3.64 0 001.43-.27 3.22 3.22 0 001.08-.71 3.07 3.07 0 00.68-1.06 3.76 3.76 0 00.24-1.35A3.17 3.17 0 0076 8.93zM87.26 8.23a3.43 3.43 0 011.18-1.31 3.81 3.81 0 012.2-.58 4.48 4.48 0 012 .38 3.67 3.67 0 011.3 1 3.62 3.62 0 01.72 1.44 6.34 6.34 0 01.22 1.7v7.27H92v-5.82c0-.32 0-.67-.05-1.07a3.61 3.61 0 00-.25-1.1 2.09 2.09 0 00-.64-.86 1.76 1.76 0 00-1.17-.35 2.72 2.72 0 00-1.21.24 2.28 2.28 0 00-.82.65 2.55 2.55 0 00-.47.93 4.44 4.44 0 00-.14 1.11v6.28h-2.87V0h2.88zM95.83 9.07V6.62h2.37V3.29h2.88v3.33h3.17v2.45h-3.17v5a2.48 2.48 0 00.33 1.37 1.39 1.39 0 001.28.5 4.25 4.25 0 00.84-.08 1.84 1.84 0 00.72-.28v2.43a4 4 0 01-1.12.3 10.44 10.44 0 01-1.16.08 5.78 5.78 0 01-1.88-.25 2.47 2.47 0 01-1.16-.77 2.84 2.84 0 01-.57-1.28 8.58 8.58 0 01-.16-1.78V9.07zM105.52 16.46a1.81 1.81 0 01.56-1.32 1.91 1.91 0 011.36-.55 2 2 0 011.35.53 1.69 1.69 0 01.57 1.3 1.81 1.81 0 01-.56 1.32 1.91 1.91 0 01-1.36.55 1.83 1.83 0 01-.73-.15 1.91 1.91 0 01-.61-.38 1.63 1.63 0 01-.42-.58 1.58 1.58 0 01-.16-.72z' />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
