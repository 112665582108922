import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Box from '@material-ui/core/Box'
import Modal from '@material-ui/core/Modal'

import Divider from '@material-ui/core/Divider'

import { Card } from './'

const AppModal = ({ open, handleClose, modalProps }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))

  const body = (
    <Box
      style={{
        position: 'absolute',
        width: isSmall ? '80%' : 500,
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        outline: 'none'
      }}
    >
      <Card>
        <Box>
          <Box mb={1}>{modalProps.header}</Box>
          <Divider />
          <Box p={2.5}>{modalProps.body}</Box>
          <Divider />
          <Box mt={1}>{modalProps.footer}</Box>
        </Box>
      </Card>
    </Box>
  )

  return (
    <Modal open={open} onClose={handleClose}>
      {body}
    </Modal>
  )
}

export default AppModal
