import { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0.5),
    borderRadius: theme.spacing(2)
  },
  activeButton: {
    margin: theme.spacing(0.5),
    borderRadius: theme.spacing(2),
    color: theme.palette.text.white
  }
}))

const FilterButton = ({ value, name, filters, setFilters }) => {
  const classes = useStyles()
  const [active, setActive] = useState(false)

  useEffect(() => {
    setActive(filters[name].includes(value))
  }, [name, value, filters])

  const handleClick = (event) => {
    const targetValue = event.currentTarget.value

    const updateArr = (arr) => {
      if (!active) {
        arr.push(targetValue)
      } else {
        const index = arr.indexOf(targetValue)
        if (index !== -1) {
          arr.splice(index, 1)
        }
      }

      return arr
    }

    const data = updateArr(filters[name])
    setFilters({ ...filters, [name]: data })

    setActive(!active)
  }

  return (
    <Button
      variant={active ? 'contained' : 'outlined'}
      size='small'
      color='primary'
      disableElevation
      className={clsx(
        !active && classes.button,
        active && classes.activeButton
      )}
      onClick={handleClick}
      value={value}
    >
      {value}
    </Button>
  )
}

export default FilterButton
