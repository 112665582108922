import { makeStyles, useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPiggyBank } from '@fortawesome/pro-regular-svg-icons'

import { Card } from '../../Components'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: theme.spacing(35),
    [theme.breakpoints.up('sm')]: {
      minHeight: theme.spacing(91)
    }
  }
}))

const Affordability = () => {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <Card>
      <Grid
        container
        direction='column'
        align='center'
        spacing={4}
        className={classes.root}
      >
        <Grid item>
          <FontAwesomeIcon
            icon={faPiggyBank}
            size='3x'
            color={theme.palette.primary.main}
          />
        </Grid>
        <Grid item>
          <Typography
            variant='h5'
            style={{ fontWeight: theme.typography.fontWeightMedium }}
          >
            We Save You Money
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1'>
            {'We’re 100% independent and '}
            <span style={{ textDecoration: 'underline' }}>
              {'negotiate with lawyers'}
            </span>
            {
              ' on your behalf. So, you save money versus hiring a lawyer directly.'
            }
          </Typography>
        </Grid>
      </Grid>
    </Card>
  )
}

export default Affordability
