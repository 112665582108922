import { Link, useHistory } from 'react-router-dom'
import Logos from '../../Components/ReviewSites'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

const WizardStepThree = ({ classes, filters, setFilters, matches }) => {
  const history = useHistory()

  const handleClick = (event) => {
    const value = event.currentTarget.value
    const data = { ...filters, sort: value }
    history.push('/getStarted/3')
    setFilters(data)
  }

  const resetFilters = () => {
    const data = {
      counties: [],
      genders: [],
      ratings: [],
      years: [],
      sort: 'listingRank'
    }
    setFilters(data)
  }

  return (
    <Container style={{ padding: '1rem 1rem 2rem 1rem' }}>
      <Grid container direction='column' spacing={2}>
        <Grid item xs={10} className={classes.header}>
          Find a Great Philadelphia Divorce Lawyer
        </Grid>
        <Grid item xs={12} className={classes.subHeader}>
          Question 3 of 3
        </Grid>
        <Grid item xs={12} className={classes.question}>
          What's{' '}
          <Box
            fontWeight='fontWeightBold'
            display='inline'
            style={{ textDecoration: 'underline' }}
          >
            most important
          </Box>{' '}
          to you in choosing a divorce lawyer?
        </Grid>
        <Grid item xs={12} container spacing={2}>
          {[
            { label: 'Years Of Experience', value: 'yearsServed' },
            { label: 'Immediate Availability', value: 'firstAvailable' },
            { label: 'Cost', value: 'rate' },
            { label: 'Online Reviews', value: 'reviewAmount' }
          ].map((options) => {
            return (
              <Grid item xs key={options.value} container justify='center'>
                <Button
                  variant='contained'
                  style={{
                    backgroundColor: '#19be8d',
                    color: 'white',
                    width: '14rem'
                  }}
                  onClick={handleClick}
                  value={options.value}
                >
                  {options.label}
                </Button>
              </Grid>
            )
          })}
        </Grid>
        <Grid item xs={12} container justify='center' className={classes.skip}>
          <Link to={'/lawyers'} onClick={resetFilters}>
            Skip - See All Lawyers
          </Link>
        </Grid>
        <Logos matches={matches} />
      </Grid>
    </Container>
  )
}

export default WizardStepThree
