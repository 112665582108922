import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import { Badge, Card, AppointmentButton } from '../../Components'
import Header from './Header'

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(20)
  },
  link: {
    textDecoration: 'underline',
    color: theme.palette.info.main,
    marginBottom: theme.spacing(3)
  },
  intro: {
    paddingLeft: theme.spacing(1.75),
    paddingRight: theme.spacing(1.75),
    marginBottom: theme.spacing(2)
  }
}))

const Compatibility = ({ compatibility, lawyer }) => {
  const classes = useStyles()
  const maxIndex = compatibility && compatibility.length - 1

  return (
    <>
      {compatibility ? (
        <Card>
          <Header title={'Compatibility'} />
          <Typography variant='body2' className={classes.intro}>
            {'Greenlight interviewed each lawyer to get a feel for their work style. Here’s what we learned about ' +
              lawyer.firstName +
              ':'}
          </Typography>
          {compatibility.map((badgeName, index) => {
            const firstPosition = 0 === index
            const lastPosition = maxIndex === index

            return (
              <Badge
                key={badgeName}
                badgeName={badgeName}
                lawyer={lawyer}
                compatibility={true}
                firstPosition={firstPosition}
                lastPosition={lastPosition}
              />
            )
          })}
          <Typography
            align='center'
            className={classes.link}
            onClick={() => alert('Feature Coming Soon')}
          >
            Find A Compatible Lawyer
          </Typography>
          <AppointmentButton lawyer={lawyer} className={classes.button} />
        </Card>
      ) : (
        <></>
      )}
    </>
  )
}

export default Compatibility
