import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { FilterButton } from '../'

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
    minWidth: '11.5rem'
  }
}))

const CountyDialog = ({ filters, setFilters }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant='text'
        className={classes.button}
      >
        <Typography
          variant='subtitle2'
          align='center'
          style={{
            fontSize: '1rem',
            width: '100%',
            maxHeight: '1.75rem',
            overflow: 'hidden'
          }}
        >
          {filters.counties.join(', ') === ''
            ? `Select a County`
            : `${filters.counties[0]} County`}
        </Typography>
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select a County</DialogTitle>
        <DialogContent>
          <Box display='flex' flexDirection='column'>
            {['Philadelphia', 'Bucks', 'Montgomery', 'Chester', 'Delaware'].map(
              (counties) => {
                return (
                  <FilterButton
                    value={counties}
                    name={'counties'}
                    filters={filters}
                    setFilters={setFilters}
                    key={counties}
                    style={{ marginTop: '1.5rem' }}
                  />
                )
              }
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Close
          </Button>
          <Button onClick={handleClose} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CountyDialog
