import { useContext, useState, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { ContentContext } from '../../Context/Content'
import { LawyersContext } from '../../Context/Lawyers'

import Grid from '@material-ui/core/Grid'

import Hero from './Hero'
import { Loading } from '../../Components/index'

import NoLawyers from './NoLawyers'
import Scroller from './Scroller'
import Guarantee from './Guarantee'
import Choices from './Choices'
import Affordability from './Affordability'
import LawyerCard from './LawyerCard'
import MobileAppBanner from './MobileAppBanner'

const Lawyers = () => {
  const { lawyers, filters } = useContext(LawyersContext)
  const { content } = useContext(ContentContext)
  const theme = useTheme()
  const [cardGrid, setCardGrid] = useState(false)
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'))

  useEffect(() => {
    const cardArr = lawyers.map((lawyer) => {
      return (
        <LawyerCard
          key={lawyer.id}
          lawyer={lawyer}
          content={content}
          county={filters.counties[0] ? filters.counties[0] : false}
        />
      )
    })

    if (isSmall) {
      cardArr.splice(3, 0, <Choices />)
      cardArr.splice(6, 0, <Affordability />)
      cardArr.splice(9, 0, <Guarantee label={'satisfaction'} />)
    }

    setCardGrid(cardArr)
  }, [lawyers, filters, isSmall, content])

  if (!cardGrid) {
    return <Loading />
  }

  return (
    <>
      {lawyers.length === 0 ? (
        <div>
          <NoLawyers />
        </div>
      ) : (
        <Grid
          container
          spacing={2}
          style={{
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
          }}
        >
          <Grid item xs={12}>
            <Hero />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {cardGrid.map((e, i) => (
                <Grid key={i} item xs={12} sm={6} md={4}>
                  {e}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Scroller />
          </Grid>
        </Grid>
      )}
      {isSmall ? <MobileAppBanner /> : null}
    </>
  )
}

export default Lawyers
