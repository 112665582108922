import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'
import Collapse from '@material-ui/core/Collapse'

const DetailsStepOne = ({ invoiceValues, tab, step }) => {
  const theme = useTheme()
  return (
    <Collapse in={step === 1 ? true : false} timeout={1000}>
      <Fade in={step === 1 ? true : false}>
        <Box>
          {invoiceValues.map((obj, i) => {
            return (
              <Grid
                container
                justify='space-between'
                key={i}
                style={{
                  marginTop: theme.spacing(2),
                  minHeight: theme.spacing(5)
                }}
              >
                <Grid item xs={6}>
                  <Typography
                    variant='body2'
                    style={{
                      fontWeight: theme.typography.fontWeightMedium
                    }}
                  >
                    {obj.name}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography
                        variant='body2'
                        align='right'
                        style={
                          tab === 'non-member' && obj.amount === 'declined'
                            ? {
                                fontWeight:
                                  theme.typography.fontWeightSemiMedium,
                                color: theme.palette.error.dark
                              }
                            : {
                                fontWeight:
                                  theme.typography.fontWeightSemiMedium
                              }
                        }
                      >
                        {obj.amount}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant='caption'
                        align='right'
                        component='div'
                        color='textSecondary'
                      >
                        {obj.amountSupport}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )
          })}
        </Box>
      </Fade>
    </Collapse>
  )
}

export default DetailsStepOne
