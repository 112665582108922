import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import TagManager from 'react-gtm-module'

const PageView = () => {
  const { pathname } = useLocation()

  const getFirstPath = (string) => {
    string.indexOf(1)
    string.toLowerCase()
    string = string.split('/')[1]
    return string
  }

  useEffect(() => {
    const page = getFirstPath(pathname)

    const tagManagerArgs = {
      dataLayer: {
        event: 'Pageview-SPA',
        pagePath: pathname,
        pageTitle: page
      }
    }

    TagManager.dataLayer(tagManagerArgs)
  }, [pathname])

  return null
}

export default PageView
