import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';

const Privacy = () => {
  const theme = useTheme();

  return (
    <Box p={2} pt={4} pb={4} style={{ color: theme.palette.text.primary }}>
      <Typography variant='body2' gutterBottom>
        LAST MODIFIED: JULY 5, 2021
      </Typography>
      <Typography variant='h6'>Privacy Policy</Typography>
      <Typography variant='body2' gutterBottom>
        Ask Greenlight, Inc. ("Company," "we," "our," or "us") is committed to
        respecting and protecting the privacy of our users. This Privacy Policy
        ("Policy"), describes how we collect, use, and disclose information that
        we obtain about visitors to our website, https://askgreenlight.com/ (the
        "Site"), our web-based platform ("Platform") and the services available
        through our Platform and Site (collectively, the "Services").
      </Typography>
      <Typography variant='body2' gutterBottom>
        At Ask Greenlight, we take data privacy seriously. This Policy describes
        our privacy practices for the activities set out in this Policy. Please
        read this Policy carefully, as it outlines how we collect, use, share,
        and otherwise process information that identifies, relates to,
        describes, is reasonably capable of being associated with, or could
        reasonably be linked, directly or indirectly, with a particular
        individual or household ("Personal Data," “Personally Identifiable
        Information,” or “PII”). Additionally, this Policy outlines your choices
        and rights with respect to our processing of your Personal Data.
      </Typography>
      <Typography variant='body2' gutterBottom>
        Your use of our Services, and any dispute over privacy, is subject to
        this Policy. Your use of our Services is also subject to our Terms of
        Use.
      </Typography>
      <Typography
        variant='subtitle1'
        gutterBottom
        style={{ fontWeight: 'bold', textDecoration: 'underline' }}
      >
        a. Information We Collect from You
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Information We Collect Directly from You.</i> We collect information
        about you directly from you and from third parties, and automatically
        through your use of our Services.
      </Typography>
      <Typography variant='body2' gutterBottom>
        The information we collect from you depends on how you use our Services.
        Information we collect may include:
      </Typography>
      <ul>
        <Typography variant='body2' gutterBottom>
          <li>
            To respond to inquiries or communications you send to us, we cullect
            contact information, such as your name, e-mail address, mailing
            address, and phone number.
          </li>
        </Typography>
        <Typography variant='body2' gutterBottom>
          <li>
            We collect the information you provide us when you fill out a
            survey, questionnaire, or other type of form you complete through
            the Services.{' '}
          </li>
        </Typography>
        <Typography variant='body2' gutterBottom>
          <li>
            While you are navigating the Site or using the Services, we may also
            collect your search queries or information about your viewing
            history.
          </li>
        </Typography>
        <Typography variant='body2' gutterBottom>
          <li>
            We collect any information you choose to e-mail or communicate to us
            while using the Services.
          </li>
        </Typography>
        <Typography variant='body2' gutterBottom>
          <li>
            We collect information you submit when inquiring about, or booking,
            consultations regarding legal services, or when you complete the
            onboarding process to hire a lawyer. This information may include
            whether you own a home, whether you have children, whether you have
            retirement assets, and other similar questions.{' '}
          </li>
        </Typography>
      </ul>
      <Typography variant='body2' gutterBottom>
        We may also collect copies of emails, text messages, and other
        correspondence you may exchange with our customers, or other customer
        records that our customers provide to us, such as terms of contracts
        governing legal services that you purchase from them. We may also
        collect and record information from customer service sessions you may
        engage in with our customers, such as phone calls, chat messages, and
        video chat sessions, for quality assurance purposes and to safeguard
        ourselves against fraudulent claims for damages. If you use our Services
        from a mobile device, that device may send us data about your location
        based on your phone settings.
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Information We Collect from Other Entities.</i> When you access the
        Services, we may collect information about you from third-party sources,
        such as: customers, service providers, business partners, public and
        third-party databases, platform providers, non-affiliated partners,
        other users of our Services, public sources, third-party advertising
        partners, or from other third parties. Information we collect may be
        combined with other information we have collected about you in order to
        complete requests you have made or facilitate transactions you have
        initiated via the Services, or for other purposes. Information we may
        collect about you from third-party sources may include your name, e-mail
        address, mailing address, phone number, and other contact information.
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Information We Collect Automatically.</i>We automatically collect
        information about your use of our Services through cookies, web beacons,
        and other technologies. To the extent permitted by applicable law, we
        combine this information with other information we collect about you,
        including your Personal Data. Please see the Our Use of Cookies and
        Other Tracking Mechanisms section below for more information.
      </Typography>
      <Typography variant='body2' gutterBottom>
        When you use our Site, we may collect your browser type and operating
        system, web pages you view on the Site, links you click on the Site, and
        your IP address. We may also collect information about the length of
        time you spent visiting our Site and/or using our Services, and the
        referring URL, or the web pages that led you to our Site.
      </Typography>
      <Typography
        variant='subtitle1'
        gutterBottom
        style={{ fontWeight: 'bold', textDecoration: 'underline' }}
      >
        b. How We Use Your Information
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Our Uses of Your Personal Data.</i> We use your information,
        including your Personal Data, for the following purposes:
      </Typography>
      <ul>
        <Typography variant='body2' gutterBottom>
          <li>To provide and maintain our Services.</li>
        </Typography>
        <Typography variant='body2' gutterBottom>
          <li>
            To communicate with you about your use of our Services, to respond
            to your inquiries, and for other customer service purposes.
          </li>
        </Typography>
        <Typography variant='body2' gutterBottom>
          <li>
            To send you information including quotes, confirmations, technical
            notices, updates, security alerts, and support and administrative
            messages.
          </li>
        </Typography>
        <Typography variant='body2' gutterBottom>
          <li>
            To compare and verify information for accuracy and to update our
            records.
          </li>
        </Typography>
        <Typography variant='body2' gutterBottom>
          <li>
            To e-mail, text message, or otherwise contact you with information
            and updates about, and changes to, the Services.
          </li>
        </Typography>
        <Typography variant='body2' gutterBottom>
          <li>
            To tailor the content and information that we may send or display to
            you, to offer location customization and personalized help and
            instructions, and to otherwise personalize your experiences while
            using the Services.
          </li>
        </Typography>
        <Typography variant='body2' gutterBottom>
          <li>
            To allow our customers to send you communications, news or updates
            about their products and services.
          </li>
        </Typography>
        <Typography variant='body2' gutterBottom>
          <li>
            To better understand how users access and use our Services, both on
            an aggregated and individualized basis.
          </li>
        </Typography>
        <Typography variant='body2' gutterBottom>
          <li>
            To handle inquiries and complaints you or other users of our
            Services submit to us.
          </li>
        </Typography>
        <Typography variant='body2' gutterBottom>
          <li>
            To improve our Service, or for research and analytics purposes.
          </li>
        </Typography>
        <Typography variant='body2' gutterBottom>
          <li>
            To administer surveys and questionnaires, such as for market
            research or user satisfaction purposes.
          </li>
        </Typography>
        <Typography variant='body2' gutterBottom>
          <li>
            To comply with legal obligations, as part of our general business
            operations, and for other business administration purposes, such as
            maintaining customer records, monitoring your compliance with any of
            your agreements with us, collecting debts owed to us, and
            safeguarding our business interests.
          </li>
        </Typography>
        <Typography variant='body2' gutterBottom>
          <li>
            Where we believe necessary to investigate, prevent or take action
            regarding illegal activities, suspected fraud, and in situations
            involving potential threats to the safety of any person or
            violations of our Terms of Use, or this Policy.
          </li>
        </Typography>
        <Typography variant='body2' gutterBottom>
          <li>
            To fulfill any other purpose for which you provide the information,
            or that you otherwise request or consent to.
          </li>
        </Typography>
      </ul>
      <Typography variant='body2' gutterBottom>
        You may instruct us to refrain from using your Personal Data in any way
        that is not required ‎for us to provide the Services to you. We will not
        refuse you access to any product or service ‎merely because you have
        advised us to stop using your Personal Data in any way ‎that is not
        required for the Services.‎ To provide us with instructions, you may
        contact us via the Contact Us section below.
      </Typography>
      <Typography
        variant='subtitle1'
        gutterBottom
        style={{ fontWeight: 'bold', textDecoration: 'underline' }}
      >
        c. How We Share Your Information
      </Typography>
      <Typography variant='body2' gutterBottom>
        We may share your information, including Personal Data, as follows:
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Our Customers.</i> We may disclose your information to our customers
        for purposes of providing the Services.
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Vendors, Service Providers, and Other Processors.</i> We may disclose
        the information we collect from you to vendors, service providers, or
        other processors, such as customer management providers and IT services
        providers, to help us provide our Services to you, to assist us in
        analyzing how our Services are used, and to provide other services.
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Subsidiaries and Affiliates.</i> We may disclose the information we
        collect from you to our affiliates or subsidiaries as needed to provide
        the Services to you.
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Other Third Parties.</i> We may also disclose the information we
        collect from you to other third parties we use to support our business.
      </Typography>
      <Typography variant='body2' gutterBottom>
        In addition, we may also share your Personal Data under the following
        circumstances:
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Business Transfers.</i> If we or our affiliates are or may be
        acquired by, merged with, or invested in by another company, or if any
        of our assets are or may be transferred to another company, whether as
        part of a bankruptcy or insolvency proceeding or otherwise, we may
        transfer the information we have collected from you to the other
        company. As part of the business transfer process, we may share certain
        of your Personal Data with lenders, auditors, and third-party advisors,
        including attorneys and consultants.
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>In Response to Legal Process.</i> We disclose your information to
        comply with the law, a judicial proceeding, court order, or other legal
        process, such as in response to a court order or a subpoena.
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>To Protect Us and Others.</i> We disclose your information when we
        believe it is appropriate to do so to investigate, prevent, or take
        action regarding illegal activities, suspected fraud, situations
        involving potential threats to the safety of any person, violations of
        our Terms of Use or this Policy, or as evidence in litigation in which
        we are involved.
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Aggregate and De-Identified Information.</i> We share aggregate or
        de-identified information about users with third parties for marketing,
        advertising, research, or other purposes.
      </Typography>

      <Typography variant='h6'>
        Our Use of Cookies and Other Tracking Mechanisms
      </Typography>
      <Typography variant='body2' gutterBottom>
        We and our third-party service providers use cookies and other tracking
        mechanisms to track information about your use of our Services. We may
        combine this information with other Personal Data we collect from you
        (and our third-party service providers may do so on our behalf).
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Cookies.</i> Cookies are alphanumeric identifiers that we transfer to
        your device through your web browser for record-keeping purposes. We may
        use cookies for multiple purposes, including to make it easier for you
        to navigate our Services or to allow us to track your activities for
        quality assurance or troubleshooting purposes while using our Services.{' '}
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Disabling Cookies.</i> Most web browsers automatically accept
        cookies, but if you prefer, you can edit your browser options to block
        them in the future. The Help portion of the toolbar on most browsers
        will tell you how to prevent your computer from accepting new cookies,
        how to have the browser notify you when you receive a new cookie, or how
        to disable cookies altogether. Visitors to our Site who disable cookies
        will be able to browse certain areas of the Site, but some features may
        not function.
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Clear GIFs, Pixel Tags and Other Technologies.</i> Clear GIFs are
        tiny graphics with a unique identifier, similar in function to cookies.
        In contrast to cookies, which are stored on your device, clear GIFs are
        embedded invisibly on web and app pages. We may use clear GIFs (a.k.a.
        web beacons, web bugs or pixel tags), in connection with our Services
        to, among other things, track the activities of Site visitors, help us
        manage content, and compile statistics about Site usage. We and our
        third-party service providers also use clear GIFs in HTML e-mails to our
        customers, to help us track e-mail response rates, identify when our
        e-mails are viewed, and track whether our e-mails are forwarded.
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Third Party Analytics.</i> We use automated devices and applications,
        such as Google Analytics, to evaluate usage of our Site. We also may use
        other analytic means to evaluate our Site. We use these tools to help us
        improve the performance of our Sites, Services, and user experiences.
        These entities may use cookies and other tracking technologies, such as
        web beacons or local storage objects (LSOs), to perform their services.
        To learn more about Google's privacy practices, please review the Google
        Policy at https://www.google.com/policies/privacy/. You can also
        download the Google Analytics Opt-out Browser Add-on to prevent their
        data from being used by Google Analytics at
        https://tools.google.com/dlpage/gaoptout.
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Cross-Device Use.</i> We and our third party service providers,
        including Google, may use the information that we collect about you
        (whether directly from our Site, through your device(s), or from a third
        party) to help us and our third party service providers identify other
        devices that you use (e.g., a mobile phone, tablet, other computer,
        etc.). We and our third-party service providers also may use the
        cross-device use and other information we learn about you to serve
        targeted advertising on your devices and to send you e-mails. To opt-out
        of cross-device advertising, you may follow the instructions set forth
        in the Third-Party Ad Networks section below.{' '}
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Do-Not-Track Signals.</i> Please note that our Site does not
        recognize or respond to any signal which your browser might transmit
        through the so-called "Do Not Track" feature your browser might have. If
        you wish to disable cookies on our Site, you should not rely on any "Do
        Not Track" feature your browser might have. For more information about
        do-not-track signals, please click here.
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Session Replay Technology.</i> Session replay technologies, such as
        LogRocket, may be used to collect information regarding visitor behavior
        on the Services, as well as PII. For more information about LogRocket,
        please see the LogRocket Policy available at
        https://logrocket.com/privacy/. You can opt out of LogRocket's
        collection and processing of data generated by your use of the Services
        by contacting LogRocket.
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Ad Networks.</i> We may use network advertisers to serve
        advertisements on non-affiliated websites or other media (e.g., social
        networking platforms). This enables us and these network advertisers to
        target advertisements to you for products and services in which you
        might be interested. Ad network providers, advertisers, sponsors and/or
        traffic measurement services may use cookies, JavaScript, pixels, LSOs
        and other tracking technologies to measure the effectiveness of their
        ads and to personalize advertising content to you. These cookies and
        other technologies are governed by each entity's specific Policy, not
        this one. We may provide these advertisers with information, including
        Personal Data, about you.
      </Typography>
      <Typography variant='body2' gutterBottom>
        Users may opt out of many ad networks. For example, you may go to the
        Digital Advertising Alliance ("DAA") Consumer Choice Page for
        information about opting out of interest-based advertising. Additional
        information is available on the DAA's website at www.aboutads.info.
      </Typography>
      <Typography variant='h6'>
        Storage of Your Personal Data and Cross-Border Transfers
      </Typography>
      <Typography variant='body2' gutterBottom>
        We use cloud services provided by third-party service providers who may
        be outside of your country of origin to store the Personal Data that we
        collect. This means that your information may be transferred to, or
        stored in, another country and accessible to foreign courts, law
        enforcement and national security authorities.
      </Typography>
      <Typography variant='body2' gutterBottom>
        You may ‎contact us (as provided in the Contact Us section below) to
        obtain information ‎about our policies ‎and ‎practices regarding our
        transfer of Personal Data across borders, ‎or to ask questions ‎about
        ‎the collection, use, disclosure or storage of Personal Data by us or
        ‎our foreign service ‎‎providers. ‎
      </Typography>
      <Typography variant='h6'>Third-Party Links</Typography>
      <Typography variant='body2' gutterBottom>
        Our Site and Services may contain links to third-party websites. Any
        access to and use of such linked websites is not governed by this
        Policy, but instead is governed by the privacy policies of those
        third-party websites. We are not responsible for the information
        practices of such third-party websites.
      </Typography>
      <Typography variant='h6'>Security of Your Personal Data</Typography>
      <Typography variant='body2' gutterBottom>
        We have implemented reasonable precautions to protect the information we
        collect from loss, misuse, and unauthorized access, disclosure,
        alteration, and destruction. Please be aware that despite our efforts,
        no data security measures can guarantee security.
      </Typography>
      <Typography variant='h6'>Access to Your Personal Data</Typography>
      <Typography variant='body2' gutterBottom>
        You may access, modify, or request correction of Personal Data that you
        have submitted by contacting us using the information in the Contact Us
        section below. We will respond to your requests to access or correct
        Personal Data we hold about you in a reasonable time and will take all
        reasonable steps to ensure that the Personal Data we hold about you
        remains accurate, up to date, and complete.
      </Typography>
      <Typography variant='h6'>
        Your Choices Regarding Our Use of Your Personal Data
      </Typography>
      <Typography variant='body2' gutterBottom>
        We may send periodic promotional emails to or SMS messages you. You may
        opt-out of promotional messages by following the opt-out instructions
        contained in them. If you opt-out of receiving promotional messages, we
        may still send you messages about your account or any services you have
        requested or received from us.
      </Typography>
      <Typography variant='h6'>Your California Privacy Rights</Typography>
      <Typography variant='body2' gutterBottom>
        Companies that collect personal information from California residents
        and disclose such information to Third Parties (including affiliated
        entities) for marketing purposes must, in response to a request by a
        consumer, either (1) provide a list detailing the categories of
        information shared and the entities to which such information was
        provided, or (2) provide a mechanism by which a consumer may opt-out of
        having their information shared with Third Parties. We have elected the
        second option and you may request that your personal information not be
        shared with Third Parties by sending your request, including your full
        name, email address and postal address to:
        privacy@askgreenlight.com.com, or by mail to Ask Greenlight, Inc., 534
        Bloomfield St., Suite 100, Hoboken, NJ 07030.
      </Typography>
      <Typography variant='body2' gutterBottom>
        In accordance with California Civil Code Sec. 1798.83, California
        resident users are entitled to know that they may file grievances and
        complaints with California Department of Consumer Affairs, 400 R Street,
        Suite 1080, Sacramento, CA 95814; or by phone at 916-445-1254 or
        800-952-5210 or by email to dca@dca.ca.gov.
      </Typography>
      <Typography variant='body2' gutterBottom>
        For more information on how your personal information is collected and
        used, please read this Privacy Policy in its entirety.
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Personally Identifiable Information (PII) Report Request.</i> In
        accordance with the California Consumer Privacy Act (CCPA) and other
        applicable state and federal regulations, you may request a report of
        personal information collected about you by AskGreenlight.com within the
        past twelve (12) months, the categories of sources from which such
        personal information was collected, the purpose of such collection and
        the categories of third parties with whom your personal information was
        shared. This complimentary report may be requested twice in a
        twelve-month period and will be provided within forty-five (45) calendar
        days from the request submission date upon verification of your
        identity. A request authentication measure is in place to ensure that
        your personal information is not being distributed to the wrong parties.
        Failure to comply with all necessary steps will result in an incomplete
        request and your consumer information report will not be sent. You may
        opt to receive your report electronically or by postal mail.
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Personal Information Removal/Deletion.</i> As a California resident,
        you also have the right to request that your personal information is
        removed from our systems.{' '}
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Right to Opt-Out.</i> As a California resident, you also have the
        right to opt-out of the sale of your PII.
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Exercising Your Rights.</i> To request your PII report, removal of
        your information from our system, or to opt out of the sale of your
        information, please contact us per the Contact section below.{' '}
      </Typography>
      <Typography variant='body2' gutterBottom>
        <i>Right Not to be Discriminated Against.</i> AskGreenlight.com shall
        not deny any good or service to you, charge you a different price,
        provide a different level or quality of any such good or service or
        suggest you will receive a different price due to your exercising any of
        your rights under the CCPA.
      </Typography>
      <Typography variant='h6'>Notice Concerning Children</Typography>
      <Typography variant='body2' gutterBottom>
        You must be at least 18 years old to use this website. We do not
        knowingly collect, use, or disclose personal information about visitors
        under 18 years of age. If you are under 18 years of age, you may not use
        the services offered on our website unless they are used with the
        consent, permission and supervision of your parents or guardians.
      </Typography>
      <Typography variant='h6'>Changes to this Policy</Typography>
      <Typography variant='body2' gutterBottom>
        This Policy is current as of the Effective Date set forth above. We may
        change this Policy from time to time, so please be sure to check back
        periodically. We will post any changes to this Policy on our Site.
      </Typography>
      <Typography variant='h6'>Contact Us</Typography>
      <Typography variant='body2' gutterBottom>
        If you have questions about the privacy aspects of our Site or Services
        or would like to make a complaint, please contact us at:
        privacy@askgreenlight.com.
      </Typography>
      <Typography variant='body2' gutterBottom>
        If you are not satisfied with our response to your question or concern,
        you may be able to file a complaint under applicable privacy laws. We
        will provide you with the contact information to do so if requested. We
        strive to offer an accessible and simple complaint procedure. We will
        promptly investigate all complaints received, and if a complaint is
        justified, we will take the necessary steps to resolve the issue in
        question.
      </Typography>
      <Typography variant='body2' gutterBottom>
        PLEASE PRINT AND RETAIN A COPY OF THIS POLICY FOR YOUR RECORDS.
      </Typography>
    </Box>
  );
};

export default Privacy;
