import { Helmet } from 'react-helmet'

const Simple = () => {
  return (
    <div className='involveme_embed' data-project='simple-divorce'>
      <Helmet>
        <script src='https://askgreenlight.involve.me/embed' />
      </Helmet>
    </div>
  )
}

export default Simple
