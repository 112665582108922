import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-scroll'
import clsx from 'clsx'
import dayjs from 'dayjs'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import FormHelperText from '@material-ui/core/FormHelperText'

import IconButton from '@material-ui/core/IconButton'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.text.primary
  },
  navbutton: {
    backgroundColor: theme.palette.grey[300],
    borderRadius: '25%'
  },
  chip: {
    width: 86,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.common.yellow,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.white,
      fontWeight: 400
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.white,
      fontWeight: 400
    }
  },
  selectedChip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.white,
    fontWeight: 400
  },
  times: {
    width: theme.spacing(38.25)
  },
  availability: {
    width: '100%',
    color: theme.palette.text.secondary
  },
  chevron: {
    height: theme.spacing(6),
    width: theme.spacing(6)
  },
  timesHeight: {
    height: theme.spacing(18)
  }
}))

const Calendar = ({ lawyer, selectedTime, setSelectedTime, formik }) => {
  const isToday = require('dayjs/plugin/isToday')
  dayjs.extend(isToday)
  const classes = useStyles()
  const [selectedDate, setSelectedDate] = useState(
    dayjs(lawyer.calendar.times[0]).format('YYYY-MM-DD')
  )
  const [lastDate, setLastDate] = useState(false)
  const [times] = useState(lawyer.calendar.times)
  const [filteredTimes, setFilteredTimes] = useState(false)

  useEffect(() => {
    setSelectedDate(dayjs(selectedTime).format('YYYY-MM-DD'))
  }, [selectedTime])

  useEffect(() => {
    const timesArr = times.filter(
      (time) => selectedDate === dayjs(time).format('YYYY-MM-DD')
    )

    setLastDate(
      selectedDate === dayjs(times[times.length - 1]).format('YYYY-MM-DD')
    )

    setFilteredTimes(timesArr)
  }, [times, selectedDate])

  const handleClick = (event) => {
    const value = event.currentTarget.value
    setSelectedDate((prevState) =>
      dayjs(prevState).add(value, 'day').format('YYYY-MM-DD')
    )
  }

  const handleSelection = (time) => {
    setSelectedTime(time)
    formik.values.datetime = time
  }

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <Box display='flex' justifyContent='space-between' width='100%'>
        <IconButton
          size='medium'
          color='inherit'
          disabled={dayjs(selectedDate).isToday()}
          onClick={handleClick}
          value={-1}
        >
          <ChevronLeftIcon className={classes.chevron} fontSize='large' />
        </IconButton>
        <Box pt={2.5}>
          <Typography
            variant='h5'
            align='left'
            className={classes.header}
            noWrap
          >
            {dayjs(selectedDate).format('ddd, MMM DD')}
          </Typography>
        </Box>
        <IconButton
          size='medium'
          color='inherit'
          disabled={lastDate}
          onClick={handleClick}
          value={1}
        >
          <ChevronRightIcon className={classes.chevron} fontSize='large' />
        </IconButton>
      </Box>

      {filteredTimes && filteredTimes.length > 0 ? (
        <Box
          display='flex'
          justifyContent='center'
          className={classes.timesHeight}
          overflow='hidden'
        >
          <Grid container justify='center'>
            <Grid
              item
              container
              className={classes.times}
              alignContent='flex-start'
            >
              {filteredTimes.map((time) => {
                return (
                  <Link
                    key={time}
                    to='contactInfo'
                    offset={-70}
                    smooth={true}
                    duration={1000}
                  >
                    <Chip
                      key={time}
                      label={dayjs(time).format('hh:mm a')}
                      className={clsx(
                        selectedTime === time ? classes.selectedChip : null,
                        classes.chip
                      )}
                      clickable
                      onClick={() => handleSelection(time)}
                    />
                  </Link>
                )
              })}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          className={classes.timesHeight}
        >
          <Typography variant='subtitle1' className={classes.availability}>
            No Time Availability
          </Typography>
          <Typography variant='caption' className={classes.availability}>
            please select a new date
          </Typography>
        </Box>
      )}
      <Box width='100%' pl={3.5}>
        <FormHelperText
          className={classes.padding}
          error={formik.touched.datetime && Boolean(formik.errors.datetime)}
        >
          {formik.touched.datetime && formik.errors.datetime}
        </FormHelperText>
      </Box>
    </Box>
  )
}

export default Calendar
