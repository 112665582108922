import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import {
  faFileSignature,
  faCreditCard,
  faBabyCarriage,
  faStars,
  faClock,
  faVideo,
  faBoxingGlove,
  faHandHoldingUsd
} from '@fortawesome/pro-light-svg-icons'
import {
  faGavel,
  faShieldCheck,
  faPeace,
  faBan
} from '@fortawesome/pro-regular-svg-icons'
import {
  faGraduationCap,
  faAward,
  faTshirt,
  faHeart,
  faPoop
} from '@fortawesome/free-solid-svg-icons'
import { faCommentsAlt } from '@fortawesome/pro-solid-svg-icons'
import { faLaughWink } from '@fortawesome/free-regular-svg-icons'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  header: {
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(1),
    fontWeight: theme.typography.fontWeightSemiMedium
  },
  subHeader: {
    paddingLeft: theme.spacing(1)
  },
  red: {
    color: theme.palette.common.red
  },
  divider: { width: theme.spacing(1) }
}))

const getBadge = (
  badgeName,
  classes,
  lawyer,
  compatibility,
  lastPosition,
  firstPosition
) => {
  let icon
  let header
  let subHeader
  let className

  let lawyerName = lawyer.firstName

  let easyValue = lawyer.percentGreatConversation
    ? parseFloat(lawyer.percentGreatConversation * 100) + '% of'
    : 'Many'

  let specialistValue = lawyer.percentFamilyLaw
    ? parseFloat(lawyer.percentFamilyLaw * 100) + '%'
    : 'A majority'

  let lovedValue =
    !lawyer.reviewAmount || lawyer.reviewAmount === 0
      ? 'many'
      : lawyer.reviewAmount

  let deepValue = lawyer.yearsServed ? lawyer.yearsServed + '+' : 'many'

  let professionalValue = lawyer.lawSchool ? lawyer.lawSchool : 'Law School '

  let activeValue = lawyer.attorneyId
    ? 'Attorney ID #' + lawyer.attorneyId
    : 'Currently active and in good standings with the bar association'

  switch (badgeName) {
    case 'digital':
      icon = faFileSignature
      header = 'All Digital Transactions'
      subHeader = 'Sign engagement letter & pay online'
      break
    case 'video':
      icon = faVideo
      header = 'Video Consultations'
      subHeader = 'Offers consultations over Zoom video'
      break
    case 'card':
      icon = faCreditCard
      header = 'Takes Credit Cards'
      subHeader = 'Accepts Credit Card Payments'
      break
    case 'specialist':
      icon = faBabyCarriage
      header = 'Family Law Specialist'
      subHeader =
        specialistValue + ' of ' + lawyerName + '’s practice is family law'
      break
    case 'easy':
      icon = faCommentsAlt
      header = 'Easy To Talk To'
      subHeader = easyValue + ' users had a “great” conversation'
      break
    case 'loved':
      icon = faStars
      header = 'Loved By Clients'
      subHeader = 'Has ' + lovedValue + ' Five-Star reviews across 24 websites'
      break
    case 'deep':
      icon = faClock
      header = 'Deep Family Law Experience'
      subHeader = 'Has practiced family law for ' + deepValue + ' years'
      break
    case 'professional':
      icon = faGraduationCap
      header = 'Professionally Trained'
      subHeader = professionalValue + ' Graduate'
      break
    case 'active':
      icon = faGavel
      header = 'Active With Pennsylvania Bar'
      subHeader = activeValue
      break
    case 'insured':
      icon = faShieldCheck
      header = 'Insured Practice'
      subHeader = 'Has Professional Liability Insurance in PA'
      break
    case 'founder':
      icon = faAward
      header = 'Greenlight Founding Member'
      subHeader = 'Among first 10 lawyers in marketplace'
      break
    case 'flat':
      icon = faHandHoldingUsd
      header = 'Flat Rate Pricing'
      subHeader = 'Offers flat rates for simple, uncontested cases'
      break
    case 'personality':
      icon = faLaughWink
      header = 'Big Personality'
      subHeader =
        lawyerName + ' is high energy, extroverted, and can light up a room.'
      break
    case 'nonsense':
      icon = faBan
      header = 'No Nonsense'
      subHeader =
        lawyerName + ' loves getting things done in as little time as possible.'
      className = classes.red
      break
    case 'warrior':
      icon = faBoxingGlove
      header = 'Warrior'
      subHeader =
        lawyerName +
        ' enjoys litigating on behalf of clients, and enjoys being in the courtroom.'
      break
    case 'low':
      icon = faTshirt
      header = 'Low Key'
      subHeader =
        lawyerName + ' has an easy-going, casual, and relaxed personality.'
      break
    case 'nurturing':
      icon = faHeart
      header = 'Nurturing'
      subHeader = lawyerName + ' regularly shows empathy and care for clients.'
      className = classes.red
      break
    case 'peace':
      icon = faPeace
      header = 'Peacemaker'
      subHeader =
        lawyerName +
        ' likes to reach peaceful, mutually agreeable solutions to problems.'
      break
    default:
      break
  }

  if (compatibility) {
    return (
      <Grid container className={lastPosition ? classes.root : null}>
        <Grid item xs={2}>
          <Divider
            orientation='vertical'
            variant='middle'
            style={
              firstPosition
                ? {
                    height: '1rem',
                    marginBottom: '.5rem',
                    marginLeft: '1.25rem',
                    backgroundColor: 'rgb(0,0,0,0)'
                  }
                : {
                    height: '1rem',
                    marginBottom: '.5rem',
                    marginLeft: '1.25rem'
                  }
            }
          />
          {badgeName === 'nonsense' ? (
            <div
              className='fa-layers fa-fw'
              style={{
                width: '2rem',
                height: '2rem'
              }}
            >
              <FontAwesomeIcon
                icon={faPoop}
                size='2x'
                transform='shrink-7'
                fixedWidth
              />
              <FontAwesomeIcon
                icon={faBan}
                size='2x'
                className={classes.red}
                fixedWidth
              />
            </div>
          ) : (
            <FontAwesomeIcon
              icon={icon}
              size='2x'
              className={className}
              fixedWidth
            />
          )}
          <Divider
            orientation='vertical'
            variant='middle'
            style={
              lastPosition
                ? {
                    height: '2rem',
                    marginTop: '.5rem',
                    marginLeft: '1.25rem',
                    backgroundColor: 'rgb(0,0,0,0)'
                  }
                : {
                    height: '2rem',
                    marginTop: '.5rem',
                    marginLeft: '1.25rem'
                  }
            }
          />
        </Grid>
        <Grid item xs={10} container>
          <Grid item xs={12} container alignContent='flex-end'>
            <Typography variant='subtitle1' className={classes.header}>
              {header}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant='body2' className={classes.subHeader}>
              {subHeader}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container className={classes.root}>
      <Grid item xs={2} container justify='center' alignContent='center'>
        <FontAwesomeIcon icon={icon} size='2x' />
      </Grid>
      <Grid item xs={10} container>
        <Grid item xs={12}>
          <Typography variant='subtitle1' className={classes.header}>
            {header}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2' className={classes.subHeader}>
            {subHeader}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

const Badge = ({
  badgeName,
  lawyer,
  compatibility,
  lastPosition,
  firstPosition
}) => {
  const classes = useStyles()
  return getBadge(
    badgeName,
    classes,
    lawyer,
    compatibility,
    lastPosition,
    firstPosition
  )
}

export default Badge
