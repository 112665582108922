import { useState, useEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { db } from '../../firebase'
import dayjs from 'dayjs'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import Nav from './Nav'
import Details from './Details'

import { Loading } from '../../Components'

import { setEvent } from '../../Utilities/functions'

const getCurrency = (value) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencySign: 'accounting'
  })

  const formatted = formatter.format(value).replace(/\D00(?=\D*$)/, '')
  return formatted
}

const Quote = () => {
  const [selectedQuote, setSelectedQuote] = useState({})
  const [step, setStep] = useState(1)
  const [tab, setTab] = useState('member')
  const [quote, setQuote] = useState(null)
  const [quoteRef, setQuoteRef] = useState(null)
  const { pathname } = useLocation()
  let { quoteId } = useParams()
  let isQuotePath = pathname.substring(1, 6) === 'quote'
  let history = useHistory()

  useEffect(() => {
    if (isQuotePath) {
      setEvent({ event: 'pageview', pathname })
    }
  }, [isQuotePath, pathname])

  useEffect(() => {
    const quotesRef = db.collection('quotes').where('id', '==', quoteId)
    quotesRef.get().then((snapshot) => {
      const quote = snapshot.docs.map((doc) => {
        const data = doc.data()
        setQuoteRef(doc.ref)
        return data
      })[0]

      if (!quote) {
        setTimeout(() => {
          history.push({ pathname: `/lawyers` })
        }, 5000)
        return setQuote('unk')
      }

      if (!quote.activeQuote) {
        setTimeout(() => {
          history.push({ pathname: `/lawyers` })
        }, 5000)
      }

      const lawyerRef = db
        .collection('lawyers')
        .where('id', '==', quote.lawyerId)
      lawyerRef.get().then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          const lawyerName = doc.data().fullName

          const quoteObj = {
            activeQuote: quote.activeQuote,
            name: quote.firstName + ' ' + quote.lastName,
            email: quote.emailAddress,
            savings: getCurrency(quote.discountAmount - quote.membershipCost),
            retainer: getCurrency(quote.retainerAmount),
            membership: getCurrency(quote.membershipCost),
            discount: getCurrency(quote.discountAmount * -1),
            total: getCurrency(
              quote.retainerAmount - quote.discountAmount + quote.membershipCost
            ),
            hourly: getCurrency(quote.hourlyRate),
            lawyerName
          }

          return setQuote(quoteObj)
        })
      })
    })
  }, [quoteId, history])

  useEffect(() => {
    if (step === 3) {
      quoteRef.set(
        {
          activeQuote: false,
          selectedQuote: { created: dayjs.utc().format(), ...selectedQuote }
        },
        { merge: true }
      )
    }
  }, [step, selectedQuote, quoteRef])

  if (!quote) {
    return <Loading />
  }

  if (quote === 'unk') {
    return (
      <Box mt={10}>
        <Typography variant='h6' align='center' gutterBottom>
          We Cannot Locate Your Quote
        </Typography>
        <Typography variant='caption' align='center' component='div'>
          Please contact your case manager if you belive this is an error. You
          are being redirected to the listing page.
        </Typography>
      </Box>
    )
  }

  if (!quote.activeQuote) {
    return (
      <Box mt={10}>
        <Typography variant='h6' align='center' gutterBottom>
          Your Quote Is No Longer Available
        </Typography>
        <Typography variant='caption' align='center' component='div'>
          Please contact your case manager if you belive this is an error. You
          are being redirected to the listing page.
        </Typography>
      </Box>
    )
  }

  return (
    <Box maxWidth={450}>
      <Nav
        tab={tab}
        setTab={setTab}
        step={step}
        setStep={setStep}
        selectedQuote={selectedQuote}
        isQuotePath={isQuotePath}
      />
      <Details
        tab={tab}
        quote={quote}
        step={step}
        setStep={setStep}
        setSelectedQuote={setSelectedQuote}
      />
    </Box>
  )
}

export default Quote
