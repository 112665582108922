import './Utilities/logrocket'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ContentProvider } from './Context/Content'
import { LawyersProvider } from './Context/Lawyers'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'

const primaryTextColor = 'rgb(44,51,54,1)'

const theme = createMuiTheme({
  palette: {
    common: {
      white: 'rgb(250, 251, 254)',
      yellow: 'rgb(254,203,51)',
      red: 'rgb(200,79,69)'
    },
    primary: {
      main: 'rgba(25, 190, 141, 1)',
      lightest: 'rgb(170,224,203)'
    },
    text: {
      disabled: 'rgba(44,51,54,0.38)',
      hint: 'rgba(44,51,54,0.38)',
      primary: primaryTextColor,
      secondary: 'rgba(44,51,54,0.56)',
      white: 'rgba(250, 251, 254, 1)'
    }
  },
  typography: {
    fontFamily: `'Poppins', sans-serif`,
    fontWeightThin: 200,
    fontWeightSemiMedium: 600,
    h1: {
      color: primaryTextColor
    },
    h2: {
      color: primaryTextColor
    },
    h3: {
      color: primaryTextColor
    },
    h4: {
      color: primaryTextColor
    },
    h5: {
      color: primaryTextColor
    },
    h6: {
      color: primaryTextColor
    },
    body1: {
      color: primaryTextColor
    },
    body2: {
      color: primaryTextColor
    },
    subtitle1: {
      color: primaryTextColor
    },
    subtitle2: {
      color: primaryTextColor
    },
    caption: {
      color: primaryTextColor
    }
  },
  breakpoints: {
    values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 }
  }
})

ReactDOM.render(
  <>
    <ThemeProvider theme={theme}>
      <ContentProvider>
        <LawyersProvider>
          <App />
        </LawyersProvider>
      </ContentProvider>
    </ThemeProvider>
  </>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
