import { useState, useEffect, createContext } from 'react'
import { db } from '../firebase'
import queryString from 'query-string'

export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
  const [content, setContent] = useState(null)
  const [loadingContent, setLoadingContent] = useState(true)

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search)

    let gclidInitial = localStorage.getItem('gclidInitial')

    if (!gclidInitial) {
      localStorage.setItem('gclidInitial', queryParams.gclid)
    }

    const sourceRef = db.collection('cms')
    sourceRef.get().then((snapshot) => {
      const content = {}
      snapshot.docs.forEach((doc) => {
        const data = doc.data()
        return (content[doc.id] = data)
      })

      setContent({ ...content, queryParams })
      setLoadingContent(false)
    })
  }, [])

  return (
    <ContentContext.Provider value={{ content, loadingContent }}>
      {children}
    </ContentContext.Provider>
  )
}
