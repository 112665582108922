import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import SectionTitle from './SectionTitle'
import ScrollButton from './ScrollButton'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClipboardListCheck,
  faPiggyBank,
  faTrophyAlt
} from '@fortawesome/pro-regular-svg-icons'

const useStyles = makeStyles((theme) => ({
  reasonRoot: { marginTop: theme.spacing(6) },
  title: { fontWeight: theme.typography.fontWeightBold },
  subtitleColor: {
    color: theme.palette.grey[600]
  },
  icon: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginBottom: theme.spacing(2)
  }
}))

const SectionDifferent = () => {
  let classes = useStyles()
  let theme = useTheme()
  let isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const reasons = [
    {
      name: `We Give You Choices`,
      message: (
        <>
          {'We work with '}
          <span style={{ textDecoration: 'underline' }}>
            {'12 local law firms'}
          </span>{' '}
          {
            ', so you have lots of options. Want to shop around? Book with up to three attorneys for free!'
          }
        </>
      ),
      icon: (
        <FontAwesomeIcon
          icon={faClipboardListCheck}
          size='3x'
          color={theme.palette.primary.main}
        />
      )
    },
    {
      name: `We Save You Money`,
      message: (
        <>
          {' '}
          {'We’re 100% independent and '}
          <span style={{ textDecoration: 'underline' }}>
            {'negotiate with lawyers'}
          </span>
          {
            ' on your behalf. So, you save money versus hiring a lawyer directly.'
          }
        </>
      ),
      icon: (
        <FontAwesomeIcon
          icon={faPiggyBank}
          size='3x'
          color={theme.palette.primary.main}
        />
      )
    },
    {
      name: `The Greenlight Guarantee`,
      message: (
        <>
          {' '}
          {
            'If you’re unhappy for any reason, we will help you find a new lawyer and recover your unused retainer funds. '
          }
          <span style={{ textDecoration: 'underline' }}>{'Anytime'}</span>
          {'.'}
        </>
      ),
      icon: (
        <FontAwesomeIcon
          icon={faTrophyAlt}
          size='3x'
          color={theme.palette.primary.main}
        />
      )
    }
  ]

  return (
    <Grid
      container
      direction='column'
      align='center'
      style={isSmall ? null : { padding: theme.spacing(4) }}
    >
      <Grid item xs={12}>
        <SectionTitle title={`How Greenlight is Different`} />
      </Grid>
      <Grid item xs={12}>
        <Grid container justify='center' spacing={isSmall ? 0 : 10}>
          {reasons.map((reason) => {
            return (
              <Grid key={reason.name} item xs={12} sm={4}>
                <Grid
                  container
                  key={reason.name}
                  className={classes.reasonRoot}
                >
                  <Grid
                    item
                    xs={12}
                    container
                    justify='center'
                    style={{ marginBottom: theme.spacing(2) }}
                  >
                    {reason.icon}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant='h6'
                      align='center'
                      className={classes.title}
                    >
                      {reason.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='subtitle1' align='center'>
                      {reason.message}
                      <Box
                        width='100%'
                        display='flex'
                        justifyContent='center'
                        mt={0.5}
                        style={
                          reason.name === '100% Satisfaction'
                            ? {}
                            : { display: 'none' }
                        }
                      >
                        <Typography
                          variant='caption'
                          className={classes.footnote}
                        >
                          *Applies only when you transact through the Greenlight
                          platform.
                        </Typography>
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={isSmall ? null : { marginTop: theme.spacing(6) }}
      >
        <ScrollButton title={'back to lawyers listing'} />
      </Grid>
    </Grid>
  )
}

export default SectionDifferent
