import { useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import { Badge, Card, AppointmentButton } from '../../Components'
import Header from './Header'

import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.main
  },
  backgroundColor: {
    backgroundColor: theme.palette.common.white
  }
}))

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0
  }
}))(MuiAccordionDetails)

const QuickFacts = ({ quickFacts, lawyer }) => {
  let classes = useStyles()
  let arrMaxIndex = quickFacts.length
  let firstFour = quickFacts.slice(0, 4)
  let remainder = quickFacts.slice(4, arrMaxIndex)

  let [expanded, setExpanded] = useState(false)
  let handleChange = (panel) => (newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <>
      {quickFacts ? (
        <Card>
          <Header title={'Quick Facts'} />
          <Grid container spacing={1}>
            {firstFour.map((badgeName) => {
              return (
                <Grid key={badgeName} item sm={12} md={6}>
                  <Badge badgeName={badgeName} lawyer={lawyer} />
                </Grid>
              )
            })}
          </Grid>
          <Accordion
            className={classes.backgroundColor}
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            square
          >
            <AccordionSummary
              style={expanded === 'panel1' ? { display: 'none' } : {}}
            >
              <Box width={'100%'} display='flex' justifyContent='center'>
                <IconButton style={{ padding: '0.5rem' }}>
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    className={classes.icon}
                    style={{ width: '2.25rem', height: '2.25rem' }}
                  />
                </IconButton>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Grid container spacing={1}>
                  {remainder.map((badgeName) => {
                    return (
                      <Grid key={badgeName} item sm={12} md={6}>
                        <Badge badgeName={badgeName} lawyer={lawyer} />
                      </Grid>
                    )
                  })}
                </Grid>
                <AppointmentButton lawyer={lawyer} />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Card>
      ) : (
        <></>
      )}
    </>
  )
}

export default QuickFacts
