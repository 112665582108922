import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'

import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import { Badge, AppointmentButton } from '../../../Components'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.info.main,
    textDecoration: 'underline'
  }
}))

const Qualifications = ({ lawyer, content }) => {
  const classes = useStyles()
  const displayPhone = content.variables.displayPhone || false

  return (
    <Box
      width='100%'
      height={displayPhone ? 352 : 328}
      pt={2.5}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
    >
      <Box>
        {lawyer.quickFacts.slice(0, 3).map((badgeName, i) => (
          <Badge key={i} badgeName={badgeName} lawyer={lawyer} />
        ))}
      </Box>
      <Box pb={1}>
        <Box>
          <AppointmentButton lawyer={lawyer} />
          <Link to={`./profile/${lawyer.id}`}>
            <Typography variant='body1' align='center' className={classes.link}>
              See More Qualifications
            </Typography>
          </Link>
        </Box>
      </Box>
      <Divider />
    </Box>
  )
}

export default Qualifications
