import { makeStyles } from '@material-ui/core/styles'
import { Element } from 'react-scroll'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import Typography from '@material-ui/core/Typography'

import Header from './Header'
import { Card, AppointmentButton } from '../../Components'
import Review from '../../Components/Review'

import StarIcon from '@material-ui/icons/Star'

const useStyles = makeStyles((theme) => ({
  intro: {
    paddingLeft: theme.spacing(1.75),
    paddingRight: theme.spacing(1.75),
    marginBottom: theme.spacing(2)
  },
  stars: {
    color: theme.palette.common.yellow,
    width: theme.spacing(3.5),
    height: theme.spacing(3.5)
  }
}))

const Reviews = ({ lawyer }) => {
  const classes = useStyles()

  const reviews = [
    {
      id: 1,
      name: 'John L.',
      message:
        'This was so much better. I talked for 30 minutes each with three attorneys. FOR FREE! And hired the one I felt most compatible with. Great new service.'
    },
    {
      id: 2,
      name: 'Josephine L.',
      message:
        'Greenlight makes it possible to find divorce lawyers very easily in my area[...] I was able to make appointments on Greenlight with several lawyers.'
    },
    {
      id: 3,
      name: 'Patricia B.',
      message:
        'As soon as I put an email in they answered me back within five minutes. I would definitely recommend, very professional.'
    }
  ]

  return (
    <Card>
      <Element name='reviews' />
      <Header title={'Reviews'} />
      <Box width='100%' display='flex' justifyContent='center' mt={3} mb={3}>
        {[1, 1, 1, 1, 1].map((e, i) => (
          <StarIcon key={i} className={classes.stars} />
        ))}
      </Box>
      <Typography variant='body2' className={classes.intro} gutterBottom>
        How this metric is determined: Greenlight counts the number of five-star
        reviews each lawyer (and/or their law firm) receives across over 25
        consumer review websites. Only five-star reviews are included.
      </Typography>
      <Typography variant='body2' className={classes.intro}>
        Here’s what people are saying about Greenlight:
      </Typography>
      <Box className={classes.intro}>
        <Grid container spacing={4}>
          {reviews.map((review) => (
            <Grid key={review.id} item sm={12} md={4}>
              <Review review={review} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <AppointmentButton lawyer={lawyer} />
    </Card>
  )
}

export default Reviews
