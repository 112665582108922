import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'

import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import { setEvent } from '../../../Utilities/functions'

const useStyles = makeStyles((theme) => ({
  root: { marginTop: theme.spacing(2), cursor: 'pointer' },
  navText: {
    fontWeight: theme.typography.fontWeightRegular
  },
  navTextSelected: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.main
  },
  selectedColor: {
    backgroundColor: theme.palette.primary.main
  }
}))

const Nav = ({ page, setPage }) => {
  let classes = useStyles()
  const { pathname } = useLocation()

  const handleClick = (e) => {
    const id = e.currentTarget.id
    const clickId = `lawyer-card-nav-${id}`
    setEvent({ event: 'click', id: clickId, pathname })
    setPage(id)
  }

  return (
    <Grid container className={classes.root}>
      {['About', 'Qualifications'].map((text, i) => {
        return (
          <Grid
            item
            xs={6}
            key={i}
            id={text.toLowerCase()}
            onClick={handleClick}
          >
            <Typography
              variant='body1'
              align='center'
              className={
                page === text.toLowerCase()
                  ? classes.navTextSelected
                  : classes.navText
              }
            >
              {text}
            </Typography>
            <Divider
              className={
                page === text.toLowerCase() ? classes.selectedColor : null
              }
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default Nav
