import { useInView } from 'react-intersection-observer'

import Grid from '@material-ui/core/Grid'

import { Card } from '../../Components'

import SectionReview from './SectionReview'
import SectionMedia from './SectionMedia'
import SectionAccessible from './SectionAccessible'
import SectionQuestions from './SectionQuestions'
import ToTop from './ToTop'
import SectionDifferent from './SectionDifferent'

const Scroller = () => {
  const [ref, inView] = useInView({
    threshold: 0
  })

  return (
    <Grid ref={ref} id='listing-scoller' container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <SectionDifferent />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <SectionMedia />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <SectionAccessible />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <SectionReview />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <SectionQuestions />
        </Card>
      </Grid>
      <ToTop inView={inView} />
    </Grid>
  )
}

export default Scroller
