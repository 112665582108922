import { makeStyles } from '@material-ui/core/styles'
import { scroller } from 'react-scroll'

import Box from '@material-ui/core/Box'

import Fab from '@material-ui/core/Fab'

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

const useStyles = makeStyles((theme) => ({
  root: { opacity: 1, transition: 'opacity 0.75s' },
  rootHidden: {
    opacity: 0,
    transition: 'opacity 0.5s',
    pointerEvents: 'none'
  },
  icon: {
    color: theme.palette.text.white,
    height: theme.spacing(4),
    width: theme.spacing(4)
  }
}))

const ToTop = ({ inView }) => {
  const classes = useStyles()

  return (
    <Box
      position='fixed'
      top={'auto'}
      left={'auto'}
      right={20}
      bottom={20}
      className={inView ? classes.root : classes.rootHidden}
    >
      <Fab
        color='primary'
        size='medium'
        onClick={() =>
          scroller.scrollTo('listing-hero', {
            duration: 3000,
            smooth: true
          })
        }
      >
        <KeyboardArrowUpIcon className={classes.icon} />
      </Fab>
    </Box>
  )
}

export default ToTop
