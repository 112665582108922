import { makeStyles, useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Fade from '@material-ui/core/Fade'
import Collapse from '@material-ui/core/Collapse'

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.text.white,
    height: theme.spacing(6),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    minWidth: theme.spacing(30)
  }
}))

const DetailsStepTwo = ({ step, setStep, setSelectedQuote }) => {
  const classes = useStyles()
  const theme = useTheme()

  const handleClick = (e) => {
    const id = e.currentTarget.id
    setSelectedQuote((prev) => {
      return { ...prev, paymentType: id }
    })
    return setStep(3)
  }

  return (
    <Collapse in={step === 2 ? true : false} timeout={1000}>
      <Fade in={step === 2 ? true : false}>
        <Box>
          <Typography
            variant='h6'
            align='center'
            style={{ marginTop: theme.spacing(2) }}
          >
            Will you be paying with Credit Card or ACH?
          </Typography>
          <Box style={{ marginTop: theme.spacing(2) }}>
            <Button
              fullWidth
              color='primary'
              variant='contained'
              disableElevation
              type='submit'
              id='cc'
              className={classes.button}
              onClick={handleClick}
            >
              Credit Card (3% fee)
            </Button>

            <Button
              fullWidth
              color='primary'
              variant='contained'
              disableElevation
              type='submit'
              id='ach'
              className={classes.button}
              onClick={handleClick}
            >
              ACH / E-CHECK (no fee)
            </Button>
          </Box>
          <Box
            width='100%'
            display='flex'
            justifyContent='center'
            style={{ marginTop: theme.spacing(2) }}
          >
            <Typography variant='caption'>
              3% Credit Card Fee Applied at Checkout
            </Typography>
          </Box>
        </Box>
      </Fade>
    </Collapse>
  )
}

export default DetailsStepTwo
