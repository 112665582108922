import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Grid from '@material-ui/core/Grid'

import SectionTitle from './SectionTitle'
import { Review } from '../../Components'
import ScrollButton from './ScrollButton'

import google_reviews from '../../Assets/google_reviews.webp'
import bbb_logo from '../../Assets/bbb_logo.png'

const useStyles = makeStyles((theme) => ({
  img: {
    height: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(7)
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(8)
    }
  },
  reviewLogos: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5)
  }
}))

const SectionReview = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const reviews = [
    {
      id: 1,
      author_name: 'John L.',
      text: 'This was so much better. I talked for 30 minutes each with three attorneys. FOR FREE! And hired the one I felt most compatible with. Great new service.'
    },
    {
      id: 2,
      author_name: 'Josephine L.',
      text: 'Greenlight makes it possible to find divorce lawyers very easily in my area[...] I was able to make appointments on Greenlight with several lawyers.'
    },
    {
      id: 3,
      author_name: 'Patricia B.',
      text: 'As soon as I put an email in they answered me back within five minutes. I would definitely recommend, very professional.'
    }
  ]

  return (
    <Grid
      container
      justify='center'
      style={isSmall ? null : { padding: theme.spacing(4) }}
    >
      <Grid item xs={12}>
        <SectionTitle title={`Here's Why People Trust Greenlight`} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={6}>
          {reviews.map((review) => (
            <Grid key={review.id} item xs={12} sm={4}>
              <Review review={review} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} className={classes.reviewLogos}>
        <Grid container spacing={3}>
          <Grid item xs={6} container justify='center'>
            <a href='https://www.google.com/search?q=greenlight+legal+service+new+jersey&ei=sjxyYMm4OI-asQWM_IOYDw&oq=greenlight+legal+service+new+jersey&gs_lcp=Cgdnd3Mtd2l6EAM6BwgAEEcQsAM6CAgAEA0QBRAeOggIABAIEA0QHjoKCAAQCBANEAoQHjoFCAAQhgM6BAghEApQlglYpRVgsxZoAXACeAGAAYQBiAH8C5IBAzcuOJgBAKABAaoBB2d3cy13aXrIAQjAAQE&sclient=gws-wiz&ved=0ahUKEwjJ2cT18fTvAhUPTawKHQz-APMQ4dUDCA0&uact=5#lrd=0x89c257761b32e9eb:0xe137b14255482b7a,1,,,'>
              <img
                className={classes.img}
                src={google_reviews}
                alt='google 5 star reviews'
              />
            </a>
          </Grid>
          <Grid item xs={6} container justify='center'>
            <a href='https://www.bbb.org/us/pa/phila/profile/sales-lead-generation/greenlight-0241-236060859/#sealclick'>
              <img
                className={classes.img}
                src={bbb_logo}
                alt='bbb accreditation'
              />
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container justify='center'>
        <ScrollButton title={'back to lawyers listing'} />
      </Grid>
    </Grid>
  )
}

export default SectionReview
