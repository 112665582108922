import MaskedInput from 'react-text-mask'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'

import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      guide={false}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(2.5)
  }
}))

const ContactForm = ({ formik }) => {
  const classes = useStyles()

  return (
    <Box id='contactInfo'>
      <InputLabel htmlFor='name'>
        <Typography
          variant='subtitle1'
          gutterBottom
          className={classes.marginTop}
        >
          Name
        </Typography>
      </InputLabel>
      <TextField
        id='name'
        type='name'
        variant='outlined'
        fullWidth
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
      />
      <InputLabel htmlFor='phone'>
        <Typography
          variant='subtitle1'
          gutterBottom
          className={classes.marginTop}
        >
          Phone
        </Typography>
      </InputLabel>
      <TextField
        id='phone'
        type='tel'
        variant='outlined'
        fullWidth
        value={formik.values.phone}
        onChange={formik.handleChange}
        error={formik.touched.phone && Boolean(formik.errors.phone)}
        helperText={formik.touched.phone && formik.errors.phone}
        InputProps={{
          inputComponent: TextMaskCustom
        }}
      />
      <InputLabel htmlFor='email'>
        <Typography
          variant='subtitle1'
          gutterBottom
          className={classes.marginTop}
        >
          Email
        </Typography>
      </InputLabel>
      <TextField
        id='email'
        type='email'
        variant='outlined'
        fullWidth
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
    </Box>
  )
}

export default ContactForm
