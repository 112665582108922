import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Slide from '@material-ui/core/Slide'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'

import Logo from '../../../Assets/logo'

import { setEvent } from '../../../Utilities/functions'

const MobileAppBanner = () => {
  const theme = useTheme()
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const { pathname } = useLocation()

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setOpen(true)
    }, 3000)

    return () => clearTimeout(timeoutId)
  }, [])

  const handleOpen = () => {
    setOpen((prev) => !prev)
  }

  const handleClick = (e) => {
    setEvent({ event: 'click', id: e.currentTarget.id, pathname })
    history.push({
      pathname: '/contact',
      state: {
        message: `Greenlight’s App to help you hire a lawyer is coming soon. Notify me when it’s ready!`
      }
    })
  }

  return (
    <Slide direction='up' in={open} mountOnEnter unmountOnExit>
      <Box
        display={open ? 'block' : 'none'}
        width='100%'
        position='fixed'
        bottom='0px'
        right='0px'
        boxShadow={24}
      >
        <Paper style={{ padding: theme.spacing(2) }}>
          <Grid container>
            <Grid item xs={1}>
              <Box
                height='100%'
                display='flex'
                alignItems='center'
                onClick={handleOpen}
              >
                <FontAwesomeIcon icon={faTimes} size='lg' />
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                height='100%'
                display='flex'
                justifyContent='center'
                alignItems='center'
              >
                <Box
                  bgcolor='primary.main'
                  height={50}
                  width={50}
                  borderRadius={12}
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                >
                  <Typography
                    variant='h5'
                    style={{
                      fontWeight: theme.typography.fontWeightBold,
                      marginLeft: theme.spacing(0.25),
                      color: theme.palette.text.white
                    }}
                  >
                    gl.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={5} style={{ paddingLeft: theme.spacing(1) }}>
              <Box mb={-0.5}>
                <Logo fill='#00bd8d' style={{ width: 85, height: 23.88 }} />
              </Box>
              <Box display='flex' alignItems='center' style={{ paddingTop: 3 }}>
                {[1, 1, 1, 1, 1].map((e, i) => (
                  <FontAwesomeIcon
                    key={i}
                    icon={faStar}
                    size='xs'
                    style={{ color: theme.palette.common.yellow }}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                height='100%'
                display='flex'
                justifyContent='center'
                alignItems='center'
              >
                <Box
                  bgcolor='primary.main'
                  borderRadius={20}
                  pl={1}
                  pr={1}
                  width={theme.spacing(12)}
                  height={theme.spacing(5)}
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  onClick={handleClick}
                  id='get-app'
                >
                  <Typography
                    variant='subtitle2'
                    style={{ color: theme.palette.text.white }}
                  >
                    Get the app
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Slide>
  )
}

export default MobileAppBanner
