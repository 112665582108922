import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import dayjs from 'dayjs'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import Typography from '@material-ui/core/Typography'

import { Card, ListingButton, ReviewSites } from '../../Components'
import { LawyerHeaderOne } from '../../Components/LawyerHeaders'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10)
  },
  bold: {
    fontWeight: theme.typography.fontWeightMedium
  },
  underLine: {
    textDecoration: 'underline'
  },
  marginTop: {
    marginTop: theme.spacing(2.5)
  },
  textWhite: {
    color: theme.palette.common.white
  },
  link: {
    color: theme.palette.info.main,
    textDecoration: 'underline'
  }
}))

const ThankYou = () => {
  const classes = useStyles()
  const location = useLocation()
  const routerState = location.state
  const lawyer = routerState.lawyer
  const firstName = lawyer.firstName
  const advancedFormat = require('dayjs/plugin/advancedFormat')
  dayjs.extend(advancedFormat)
  const [cory, setCory] = useState(false)
  const theme = useTheme()

  useEffect(() => {
    setCory(lawyer.id === '70396232-cfc1-4b38-86cf-fcb5a3179688')
  }, [lawyer])

  return (
    <Grid
      container
      justify='center'
      spacing={2}
      style={{
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
      }}
    >
      <Grid item xs={12} sm={8}>
        <Card>
          <LawyerHeaderOne lawyer={lawyer} />
          <Typography variant='body1' className={classes.marginTop}>
            {'Your appointment with ' + firstName + ' is confirmed at '}
            <span className={`${classes.bold} ${classes.underLine}`}>
              {dayjs(routerState.datetime).format('hh:mm a') +
                ' on ' +
                dayjs(routerState.datetime).format('MMMM Do') +
                '.'}
            </span>
          </Typography>
          {cory ? null : (
            <>
              <Typography
                variant='body1'
                color='primary'
                align='left'
                className={`${classes.marginTop} ${classes.bold}`}
              >
                Next Steps:
              </Typography>
              {[
                {
                  number: '1',
                  text: (
                    <div>
                      {'Complete your '}
                      <a
                        className={classes.link}
                        href='https://askgreenlight.involve.me/case-profile'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {'case profile'}
                      </a>
                      {' to get the most out of your consultation'}
                    </div>
                  )
                },
                {
                  number: '2',
                  text: `${firstName} will call you at ${routerState.phone}`
                },
                {
                  number: '3',
                  text: `Greenlight will ask how the call went (over text)`
                },
                {
                  number: '4',
                  text: lawyer.deliveryManagement
                    ? `Greenlight will email instructions on how to hire ${firstName}`
                    : `${firstName} will share info on how to get started`
                }
              ].map((e, i) => {
                return (
                  <Box
                    key={i}
                    display='flex'
                    alignItems='center'
                    className={classes.marginTop}
                  >
                    <Box
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      pr={2}
                    >
                      <Box
                        bgcolor='primary.main'
                        height={28}
                        width={28}
                        borderRadius='50%'
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                      >
                        <Typography
                          variant='subtitle2'
                          className={classes.textWhite}
                        >
                          {e.number}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant='body1'>{e.text}</Typography>
                    </Box>
                  </Box>
                )
              })}
            </>
          )}
        </Card>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Card>
          <Typography
            variant='h6'
            color='textPrimary'
            align='left'
            className={classes.bold}
          >
            Did you know?
          </Typography>
          <Typography variant='body1' className={classes.marginTop}>
            Before deciding who to hire, you can book free consultations with up
            to 3 divorce lawyers!
          </Typography>
          <Box mt={3} mb={3}>
            <ReviewSites />
          </Box>
          <Box>
            <ListingButton cory={cory} />
          </Box>
        </Card>
      </Grid>
    </Grid>
  )
}

export default ThankYou
