import { makeStyles } from '@material-ui/core/styles'
import { scroller } from 'react-scroll'

import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.white,
    height: theme.spacing(6),
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    marginBottom: '0.5rem',
    width: '16rem',
    fontSize: '1rem'
  }
}))

const ScrollButton = ({ title }) => {
  const classes = useStyles()

  return (
    <Button
      color='primary'
      variant='contained'
      disableElevation
      className={classes.root}
      onClick={() =>
        scroller.scrollTo('listing-hero', {
          duration: 3000,
          smooth: true
        })
      }
    >
      {title}
    </Button>
  )
}

export default ScrollButton
