import Box from '@material-ui/core/box'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles((theme) => ({
  color: {
    color: theme.palette.text.secondary
  }
}))

const Header = ({ title, listing }) => {
  const classes = useStyles()

  return (
    <Box mb={2.5}>
      <Typography
        className={listing ? classes.color : null}
        variant={listing ? 'subtitle1' : 'h6'}
        gutterBottom
        align='left'
        noWrap={true}
      >
        {title}
      </Typography>
      <Divider />
    </Box>
  )
}

export default Header
