import { makeStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'

import Typography from '@material-ui/core/Typography'
import { Card } from '../../Components'
import Header from './Header'
import AppointmentButton from '../../Components/AppointmentButton'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary
  },
  question: {
    fontWeight: theme.typography.fontWeightSemiMedium
  }
}))

const Interview = ({ lawyer }) => {
  const classes = useStyles()
  const interview = lawyer.profile.interview

  return (
    <Card>
      <Header title={'Interview'} />
      {interview.map((obj, i) => {
        return Object.entries(obj).map((question) => {
          const [key, value] = question
          return (
            <Container key={i} className={classes.root}>
              <Typography
                variant='subtitle1'
                className={classes.question}
                gutterBottom
              >
                {key}
              </Typography>
              <Typography variant='body2' paragraph gutterBottom>
                {value}
              </Typography>
            </Container>
          )
        })
      })}
      <AppointmentButton lawyer={lawyer} />
    </Card>
  )
}

export default Interview
