import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles((theme) => ({
  label: {
    marginTop: theme.spacing(2.5),

    '& span': {
      fontSize: '0.75rem',
      lineHeight: '1.5'
    }
  }
}))

const Consent = ({ formik }) => {
  const classes = useStyles()

  const data = formik.values.consent

  return (
    <Box>
      <FormControlLabel
        className={classes.label}
        label={data.label}
        control={
          <Checkbox
            value={true}
            onChange={formik.handleChange}
            color='primary'
            name={`consent.value`}
            style={
              formik.touched.consent && Boolean(formik.errors.consent)
                ? { color: 'red' }
                : {}
            }
          />
        }
      />
      <Box pl={3.5}>
        <FormHelperText
          error={
            formik.touched.consent &&
            Boolean(formik.errors.consent) &&
            'value' in formik.errors.consent &&
            Boolean(formik.errors.consent.value)
          }
        >
          {formik.touched.consent &&
            formik.errors.consent &&
            'value' in formik.errors.consent &&
            formik.errors.consent.value}
        </FormHelperText>
      </Box>
    </Box>
  )
}

export default Consent
